import { apiSlice } from '../features/api/apiSlice';
import {
  getIdToken,
  getShowSignInModal,
  setIdToken,
  setShowSignInModal,
} from '../features/sso/authSlice';
import { useAppDispatch, useAppSelector } from './reduxHooks';

export default function useLoginLogout() {
  const dispatch = useAppDispatch();
  const showSignInModal = useAppSelector(getShowSignInModal);
  const toggleSignIn = () => dispatch(setShowSignInModal(!showSignInModal));

  const token = useAppSelector(getIdToken);
  const logout = () => {
    sessionStorage.removeItem('idp');
    sessionStorage.removeItem('auth_login_state');
    // TODO: Remove the user query from the redux store entirely
    // ?: currently the user is just se to null, but ID token is still in the store
    dispatch(apiSlice.util.invalidateTags(['Token']));
    dispatch(setIdToken({ idToken: null }));
    dispatch(
      apiSlice.util.updateQueryData('user', { token: token || '' }, () => null),
    );
  };

  return [toggleSignIn, logout];
}
