import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { faChevronDown, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../hooks/reduxHooks';
import { getSearchQuery, setSearchQuery } from '../features/search/searchSlice';
import { setShowSignInModal } from '../features/sso/authSlice';
import { classNames, getIDType, resolveQs } from '../utils/commonUtils';
import { useResolveQuery } from '../store';
import { baseQuery } from '../utils/searchUtils';
import useSingleSignOn from '../hooks/useSingleSignOn';
import ClassicModeLink from './ClassicModeLink';
import Spinner from './Spinner';
import Error from './Error';
import useCurrentQuery from '../hooks/useCurrentQuery';
import { ILandingPageForm } from '../types/commonTypes';
import { getResolutionId, setResolutionId } from '../features/resolve/resolveSlice';
import SearchBtn from '../features/search/SearchBtn';

function LandingPage(): React.ReactElement {
  const resolutionId = useAppSelector(getResolutionId);
  const searchQuery = useAppSelector(getSearchQuery);
  const [localResolutionId, setLocalResolutionId] = useState<string>(resolutionId || '');
  const [searchStr, setSearchStr] = useState<string>(searchQuery?.resourceName?.resourceName || '');
  const token = useAppSelector((state) => state.authentication.idp.idToken);
  const isLoggedIn = !!token;
  const [searchOption, setSearchOption] = useState<string>('id');
  const [placeholderText, setPlaceholderText] = useState<string>('');
  const [registryError, setRegistryError] = useState<boolean>(false);
  const formSubmitted = useRef<boolean>(false);
  const sso = useSingleSignOn();
  const dispatch = useAppDispatch();

  const {
    isSuccess: resolveSuccess,
    isLoading: resolveLoading,
    error: resolveError,
  } = useResolveQuery(resolutionId, {
    skip: !resolutionId || !formSubmitted.current,
  });

  const {
    isSuccess: searchSuccess,
    isLoading: searchLoading,
    error: searchError,
  } = useCurrentQuery(!formSubmitted.current || !searchQuery);

  const navigate = useNavigate();
  useEffect(() => {
    if (resolveSuccess && resolutionId) {
      navigate(resolveQs(resolutionId));
    }

    if (searchSuccess) {
      navigate('/search/results');
    }
  }, [resolveSuccess, navigate, resolutionId, searchSuccess, searchOption]);

  useEffect(() => {
    if ((searchError as any)?.status === 401) {
      dispatch(setShowSignInModal(true));
    }
  }, [searchError, dispatch]);

  useEffect(() => {
    if (!isLoggedIn) {
      setPlaceholderText('Resolve a search by EIDR ID or Alt ID');
    } else if (isLoggedIn && searchOption === 'id') {
      setPlaceholderText('Search by Title or Resolve an ID');
    } else if (isLoggedIn && searchOption === 'party') {
      setPlaceholderText('Search by Party or Resolve an ID');
    } else if (isLoggedIn && searchOption === 'service') {
      setPlaceholderText('Search by Video Service or Resolve an ID');
    }
  }, [isLoggedIn, searchOption]);
  
  useEffect(() => {
    if (searchOption !== 'id' && isLoggedIn) {
      setRegistryError(true);
    } else {
      setRegistryError(false);
    }
  }, [searchOption, isLoggedIn])

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ILandingPageForm>();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (searchOption === 'id') {
      setLocalResolutionId(e.target.value);
    } else {
      setSearchStr(e.target.value);
    }
    if (resolutionId !== ''){
      dispatch(setResolutionId({resolutionId:''}));
    };
  };

  const registryOptions = [
    { value: 'id', name: 'Title' },
    { value: 'party', name: 'Party' },
    { value: 'service', name: 'Video Service' },
  ];
  const unauthRegistryOption = [
    {value: 'id', name: 'EIDR ID/Alt ID'},
  ];

  const handleOptionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value;
    setSearchOption(selectedValue);
    setPlaceholderText(selectedValue);
    setLocalResolutionId('');
    setSearchStr('');
    dispatch(setResolutionId({ resolutionId: '' }));
    dispatch(setSearchQuery(null));
  };

  const handleFocus = () => {
    // Check which state variable is currently controlling the input's value
    // and update it accordingly
    if (searchOption === 'id') {
      // If the input is controlled by localResolutionId, trim and update it
      // Assuming there's a setLocalResolutionId setter function
      setLocalResolutionId(localResolutionId.trim());
    } else {
      // If the input is controlled by searchStr, trim and update it
      setSearchStr(searchStr.trim());
    }
  };

  const generateSearchQuery = (searchInput: string) => {
    const countryOfOrigin: string[] = [];
    const originalLanguages: string[]  = [];
    return {
      ...baseQuery,
      resourceName: {
        resourceName: searchInput,
        alternateResourceNameAddition: true,
      },
      filters: {
        rootTitleRecords: true,
        compilations: true,
        series: true,
        episodes: true,
      },
      countryOfOrigin,
      originalLanguages,
    };
  };

  const displayedOptions = isLoggedIn ? registryOptions : unauthRegistryOption;

  const onSubmit: SubmitHandler<ILandingPageForm> = ({ searchInput}) => {
    formSubmitted.current = true;
    if (searchOption === 'id') {
      // Handle 'id' referent type
      switch (getIDType(searchInput)) {
        case 'content':
          dispatch(setSearchQuery(null));
          dispatch(setResolutionId({ resolutionId: searchInput }));
          break;
        case 'party':
          setRegistryError(true);
          break;
        case 'video service':
          setRegistryError(true);
          break;
        default:
          dispatch(setResolutionId({ resolutionId: '' }));
          dispatch(setSearchQuery(generateSearchQuery(searchInput)));
          break;
      }
    } else {
      setRegistryError(true);
    }
    console.log('Form submitted');
  };

  return (
    <>
      <div className="w-full bg-theme-blue py-4 px-20 text-white text-2xl">
        Search
      </div>
      <div className="mt-24 flex px-4">
        <Helmet>
          <title>Search</title>
          <meta name="description" content="Search for EIDR content IDs, party IDs, and video service IDs using our comprehensive registry search tool." />
        </Helmet>
        <div className="w-full flex justify-center">
          <div className="max-w-4xl w-full">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className={classNames(
                'border relative',
                'justify-between flex-1',
                'flex rounded border-theme-gray-200',
              )}
            >
              <div
                className={classNames(
                  'w-48 h-full border-theme-gray-200 bg-opacity-20',
                  'rounded-l border-r bg-theme-gray-200 relative',
                )}
              >
                <div className="flex relative w-full h-full">
                  <select
                    id="registry"
                    {...register('searchOption')}
                    className={classNames(
                      'rounded-m appearance-none p-3 z-20',
                      'bg-transparent h-full w-full cursor-pointer',
                    )}
                    value={searchOption}
                    onChange={handleOptionChange}
                    disabled={!isLoggedIn}
                  >
                    {displayedOptions.map(({ name, value }) => (
                      <option value={value} key={`refTypeOption-${name}`}>
                        {name}
                      </option>
                    ))}
                  </select>
                  {isLoggedIn && (
                    <FontAwesomeIcon
                      icon={faChevronDown}
                      className={classNames(
                        'absolute right-4 top-1/2 -translate-y-1/2 pointer-events-none',
                        'text-theme-gray-200',
                      )}
                  />)}
                </div>
              </div>

              <label htmlFor="searchInput" className="w-full">
                <input
                  placeholder={placeholderText}
                  {...register('searchInput', {
                    required: { value: true, message: 'This is a required field.' },
                    validate: {
                      checkIdType: (val) => {
                        if (searchOption !== 'all') {
                          const idType = getIDType(val);
                          return !(!idType && !isLoggedIn) || 'Invalid EIDR ID';
                        }
                        return true;
                      },
                    },
                  })}
                  className={classNames(
                    'rounded-r p-3 w-full text-sm md:text-base',
                  )}
                  
                  value={searchOption === 'id' ? localResolutionId : searchStr}
                  onChange={handleInputChange}
                  onFocus={handleFocus}
                />
              </label>
              {errors?.searchInput && (
                <div className="absolute top-20 left-0 text-red-400">
                  <p>{errors.searchInput.message}</p>
                </div>
              )}
              {registryError && (
                <div className="absolute top-20 left-0 text-red-400">
                  <p>Selected registry not implemented, please use Legacy Web UI</p>
                </div>
              )}
              <div className="h-full flex items-center my-auto absolute right-4">
                {resolveLoading || searchLoading ? (
                  <Spinner />
                ) : (
                  <button type="submit">
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                  </button>
                )}
              </div>
            </form>
            {sso && (
              <div className="mt-2 flex justify-between">
                <ClassicModeLink />
                <SearchBtn isAdvanced={false} />
              </div>
            )}
            {!isLoggedIn && (
              <div className="flex justify-end mt-2 text-sm md:text-base">
                <button
                  type="button"
                  className="text-theme-blue hover:underline"
                  onClick={() => dispatch(setShowSignInModal(true))}
                >
                  Sign in
                </button>
                <span className="ml-1 text-theme-black">
                  for advanced features
                </span>
              </div>
            )}
            {resolveError && (
              <Error>
                <p>Oops! We couldn&apos;t find that record, please try again.</p>
              </Error>
            )}
            {searchError && (searchError as any).status !== 401 && (
              <Error>
                <p>Oops! An error occurred, please try again.</p>
              </Error>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default LandingPage;
