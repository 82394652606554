import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FormEvent, useState } from 'react';
import { classNames } from '../../utils/commonUtils';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { getSearchQuery, setSearchQuery } from './searchSlice';
import { sortOptions } from '../../utils/searchUtils';
import { SortKeys } from '../../types/searchTypes';

export default function SortAndOrder() {
  const [open, setOpen] = useState(false);

  const searchQuery = useAppSelector(getSearchQuery);
  const dispatch = useAppDispatch();

  const onSortChange = (e: FormEvent<HTMLInputElement>) => {
    dispatch(
      setSearchQuery({
        ...searchQuery,
        sort: e.currentTarget.value as SortKeys,
      }),
    );
  };

  const onOrderChange = (e: FormEvent<HTMLInputElement>) => {
    dispatch(
      setSearchQuery({
        ...searchQuery,
        order: e.currentTarget.value as 'asc' | 'desc',
      }),
    );
  };

  return (
    <div className="relative">
      <div
        className={classNames(
          open
            ? 'rounded border-theme-gray-200 bg-theme-gray-100'
            : 'border-transparent',
          'absolute flex flex-col border -translate-y-1 w-max right-0',
        )}
      >
        <div
          className={classNames(
            'w-full flex justify-end mt-1 transition-transform',
            open ? '-translate-x-2' : '',
          )}
        >
          <button type="button" className="flex" onClick={() => setOpen(!open)}>
            <span>Sort Results</span>
            <div className="flex flex-col relative w-min translate-y-1 ml-1">
              <span className="absolute -translate-y-[6px]">
                <FontAwesomeIcon icon={faCaretDown} className="rotate-180" />
              </span>
              <span>
                <FontAwesomeIcon icon={faCaretDown} />
              </span>
            </div>
          </button>
        </div>
        {open ? (
          <div className={classNames('px-4 pb-4 w-max flex flex-col right-0')}>
            <span className="mb-1 text-base font-medium">Order:</span>
            <div className="h-[1px] bg-theme-gray-200" />
            <div className="flex my-1">
              {['asc', 'desc'].map((item) => (
                <label className="mr-2">
                  <input
                    type="radio"
                    name="order"
                    value={item}
                    checked={searchQuery?.order === item}
                    onChange={onOrderChange}
                  />
                  <span className="ml-2">{item}</span>
                </label>
              ))}
            </div>

            <span className="text-base font-medium">Sort By:</span>
            <div className="h-[1px] bg-theme-gray-200 my-1" />
            {sortOptions.map((item) => (
              <label className="capitalize">
                <input
                  type="radio"
                  name="sort"
                  value={item.value}
                  checked={searchQuery?.sort === item.value}
                  onChange={onSortChange}
                />
                <span className="ml-2">{item.name}</span>
              </label>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
}
