import React from 'react';
import useSingleSignOn from '../../hooks/useSingleSignOn';
import { classNames } from '../../utils/commonUtils';
import useLoginLogout from '../../hooks/useLoginLogout';

function UserProfile() {
  const [, logout] = useLoginLogout();

  const { name, picture } = useSingleSignOn();
  // TODO: Remove "sign out" button and place in header with "sign in" button
  return (
    <div>
      {name && picture ? (
        <div className="flex items-center font-roboto">
          <div className="w-10 mx-2">
            <img className="rounded-full" src={picture} alt="User Profile" />
          </div>
          <div className="ml-2">
            <p>{name}</p>
            <button
              type="button"
              className={classNames(
                'hover:bg-opacity-80 transition-colors',
                'text-white bg-theme-blue py-2 px-4 text-sm font-medium rounded',
              )}
              onClick={logout}
            >
              Sign out
            </button>
          </div>
        </div>
      ) : (
        <p />
      )}
    </div>
  );
}

export default UserProfile;
