import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { SearchPayloadLimited } from '../../types/searchTypes';
import config from '../../config';

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({ baseUrl: `${config.apiUrl}` }),
  keepUnusedDataFor: 0,
  tagTypes: ['Resolution', 'Search', 'User', 'Token'],
  endpoints: (builder) => ({
    resolve: builder.query({
      query: (resolutionId: string | null) => ({
        method: 'POST',
        url: config.resolve,
        body: { resolutionId },
      }),
      providesTags: ['Resolution'],
    }),
    search: builder.query({
      query: (params: { body: SearchPayloadLimited; token: string }) => ({
        method: 'POST',
        url: config.search,
        body: params.body,
        headers: {
          authorization: `Bearer ${params.token}`,
        },
        cache: 'no-store',
      }),
    }),
    token: builder.query({
      query: (body: { code: string; idp: string; redirect_uri: string }) => ({
        method: 'POST',
        url: config.token,
        body,
      }),
      providesTags: ['Token'],
    }),
    initLogin: builder.query<void, void>({
      query: () => config.initlogin,
    }),
    user: builder.query({
      query: (body: { token: string }) => ({
        method: 'POST',
        url: config.user,
        body,
        headers: {
          authorization: `Bearer ${body.token}`,
        },
      }),
      providesTags: ['User'],
    }),
    isOnline: builder.query({
      query: () => config.isonline,
    }),
  }),
});

export const {
  useResolveQuery,
  useSearchQuery,
  useTokenQuery,
  useInitLoginQuery,
  useIsOnlineQuery,
  useUserQuery,
} = apiSlice;
