import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import RecordView from './features/resolve/RecordView';
import LandingPage from './components/LandingPage';
import SearchResults from './features/search/SearchResults';
import useSingleSignOn from './hooks/useSingleSignOn';
import EidrHeader from './components/EidrHeader';
import LinkBanner from './components/LinkBanner';
import EidrFooter from './components/EidrFooter';
import AdvancedSearch from './features/search/AdvancedSearch';
import { useAppSelector } from './hooks/reduxHooks';
import { getShowSignInModal } from './features/sso/authSlice';
import SignInModal from './features/sso/SignInModal';
import RegisterPage from './components/RegisterPage';
import PageNotFound from './components/PageNotFound';
import MobileMenu from './components/MobileMenu';
import Help from './components/Help';
import Redirect from './components/Redirect';
import Language from './components/Language';
import config from './config';
import SandboxOverlay from './components/SandboxOverlay';

export default function App() {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const sso = useSingleSignOn();

  const showSignInModal = useAppSelector(getShowSignInModal);

  return (
    <>
      <main className="flex flex-col h-full relative z-30">
        <div className="lg:hidden">
          {showMobileMenu ? (
            <MobileMenu setShowMobileMenu={setShowMobileMenu} />
          ) : null}
        </div>
        <div className="flex-shrink">
          <EidrHeader setShowMobileMenu={setShowMobileMenu} />
          <div className="hidden lg:block">
            <LinkBanner />
          </div>
        </div>
        <section className="flex-grow">
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/search" element={<LandingPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/search/results/*" element={<SearchResults />} />
            {sso && <Route path="/search/advanced" element={<AdvancedSearch />} />}
            <Route path="/view/content" element={<RecordView />} />
            <Route path="/redirect" element={<Redirect />} />
            <Route path="/help" element={<Help />} />
            <Route path="*" element={<PageNotFound />} />
            <Route path="/language" element={<Language />} />

          </Routes>
        </section>
        <div className="flex-shrink">
          <EidrFooter />
        </div>
        {showSignInModal && <SignInModal />}
      </main>
      {config.displayOverlay && <SandboxOverlay />}
    </>
  );
}
