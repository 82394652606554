import { IStackListItem } from '../types/commonTypes';
import PipeList from './PipeList';

export default function StackList({ items }: { items: IStackListItem[] }) {
  return (
    <>
      {items.map((item) => (
        <div className="mb-2" key={`${item.value}`}>
          <p className="font-bold">{item.value}</p>
          {item.pipeList ? <PipeList fields={item.pipeList} /> : null}
        </div>
      ))}
    </>
  );
}
