import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { copyToClipboard } from '../utils/commonUtils';
import copy from '../assets/copy.svg';

export default function CopyButton({ content }: { content: string }) {
  const [showCheck, setShowCheck] = useState(false);

  return (
    <button
      type="button"
      className="text-black hover:opacity-50 mr-2"
      onClick={() => {
        copyToClipboard(content);
        setShowCheck(true);

        setTimeout(() => {
          setShowCheck(false);
        }, 250);
      }}
    >
      {showCheck ? (
        <FontAwesomeIcon icon={faCheck} />
      ) : (
        <img src={copy} alt="copy icon" />
      )}
    </button>
  );
}
