import { createSlice } from '@reduxjs/toolkit';
import type { AuthState, ProviderTypes } from '../../types/authTypes';
import type { RootState } from '../../types/commonTypes';

const initialState: AuthState = {
  idp: {
    idToken: null,
    provider: null,
  },
  showSignInModal: false,
};

const authSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    setIdToken(state, action: { payload: { idToken: string | null } }) {
      state.idp = { ...state.idp, idToken: action.payload.idToken };
    },
    setIdp(state, action: { payload: { idp: ProviderTypes | null } }) {
      state.idp = { ...state.idp, provider: action.payload.idp };
    },
    setShowSignInModal(state, action: { payload: boolean }) {
      state.showSignInModal = action.payload;
    },
  },
});

export default authSlice.reducer;

export const { setIdToken, setIdp, setShowSignInModal } = authSlice.actions;

export const getIdp = (state: RootState) => state.authentication.idp;

export const getIdToken = (state: RootState) =>
  state.authentication.idp.idToken;

export const getShowSignInModal = (state: RootState) =>
  state.authentication.showSignInModal;

export const getProvider = (state: RootState) =>
  state.authentication.idp.provider;
