import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faCode } from '@fortawesome/free-solid-svg-icons';
import ContentRecord from './ContentRecord';
import { useResolveQuery } from '../api/apiSlice';
import ExpandableField from './ExpandableField';
import { concatUrl, getIDType } from '../../utils/commonUtils';
import Spinner from '../../components/Spinner';
import ViewEJson from '../../components/ViewEJson';
import ViewXML from '../../components/ViewXML';
import config from '../../config';
import Error from '../../components/Error';
import brackets from '../../assets/brackets.svg';

function RecordView() {
  const [params] = useSearchParams();
  const [showXML, setShowXML] = useState(false);
  const [showJSON, setShowJSON] = useState(false);

  const [resolutionId, idType] = useMemo(() => {
    const id = params.get('id');
    const type = getIDType(id || '');

    return [id, type];
  }, [params]);

  const {
    data, originalArgs, isError, isLoading, isFetching,
  } = useResolveQuery(resolutionId || '', {
    skip: !resolutionId,
  });

  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  const handleViewXML = () => {
    setShowXML(!showXML);
  };

  const handleViewJson = () => {
    setShowJSON(!showJSON);
  };

  useEffect(() => {
    setShowXML(false);
    setShowJSON(false);
  }, [resolutionId]);

  return (
    <div>
      <div className="w-full bg-theme-blue py-4 px-20 text-white text-2xl capitalize">
        {`${idType || ''} Record View`}
      </div>
      <div className="grid grid-cols-3 gap-4 px-4 lg:px-20 mt-14 max-w-7xl">
        <div className="col-span-3 flex justify-between items-center mb-4">
          <button type="button" onClick={goBack} className="text-theme-blue flex items-center">
            <FontAwesomeIcon icon={faChevronLeft} />
            <span className="ml-2">Back to search</span>
          </button>
          <div className="flex">
            <button type="button" onClick={handleViewXML} className="text-theme-blue mr-4 flex items-center">
              <FontAwesomeIcon icon={faCode} className="ml-2" />
              <span className="ml-2">View XML</span>
            </button>
            <button type="button" onClick={handleViewJson} className="text-theme-blue mr-4 flex items-center">
              <img src={brackets} alt="code brackets" className="ml-2" />
              <span className="ml-1">View JSON</span>
            </button>
          </div>
        </div>
        {isFetching || isLoading ? (
          <div className="w-full p-4 flex justify-center">
            <Spinner />
          </div>
        ) : null}
        {showXML && (
          <div className="col-span-3">
            <h2 className="text-xl font-bold mb-2">XML Data</h2>
            <ViewXML />
          </div>
        )}
        {showJSON && (
          <div className="col-span-3">
            <h2 className="text-xl font-bold mb-2">JSON Data</h2>
            <ViewEJson
              response={data}
              request={originalArgs ? {
                endpoint: concatUrl(config.apiUrl, config.resolve),
                body: { resolutionId: originalArgs },
              } : undefined}
            />
          </div>
        )}
        <div className="col-span-3 border border-theme-blue border-opacity-20 rounded flex flex-col p-4 w-full bg-white">
          {data && idType === 'content' && (
            <ContentRecord response={data} showXML={false} />
          )}
          {data && (idType === 'video service' || idType === 'party') && (
            <ExpandableField data={{ someData: data }} canClose={false} defaultOpen />
          )}
        </div>
        {isError && (
          <Error>
            <p>Oops, we ran into an issue, please try your search again.</p>
          </Error>
        )}
      </div>
    </div>
  );
}

export default RecordView;
