import { FormEvent, useMemo } from 'react';
import { classNames, getRandomString } from '../../utils/commonUtils';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { getSearchQuery, setSearchQuery } from './searchSlice';

export default function Pagination({
  totalMatches,
  pageSize,
  pageNumber,
}: {
  totalMatches: number;
  pageSize: number;
  pageNumber: number;
}) {
  // ? Max Results is 500 and page size is unchangeable by the user, so
  // ? that makes the max pages = 50 (account for double digit button styling)
  const totalPages = useMemo(
    () => Math.ceil(Number(totalMatches / pageSize)),
    [totalMatches, pageSize],
  );

  const pages = useMemo(() => {
    const allPages = Array.from(Array(totalPages + 1).keys()).slice(1);

    if (allPages.length <= 5) {
      return allPages;
    }

    let pageSelection: number[] = [];

    if (pageNumber >= 4) {
      pageSelection = [pageNumber - 1, pageNumber, pageNumber + 1];
    }

    if (pageNumber < 4) {
      pageSelection = [2, 3, 4];
    }

    if (pageNumber + 2 >= allPages.length) {
      pageSelection = [
        allPages.length - 3,
        allPages.length - 2,
        allPages.length - 1,
      ];
    }

    return [allPages[0], ...pageSelection, allPages[allPages.length - 1]];
  }, [totalPages, pageNumber]);

  const searchQuery = useAppSelector(getSearchQuery);
  const dispatch = useAppDispatch();

  const onClick = (e: FormEvent<HTMLButtonElement>) => {
    dispatch(
      setSearchQuery({
        ...searchQuery,
        pageNumber: Number(e.currentTarget.value),
      }),
    );
  };

  const ellipse = (
    <div className="h-8 flex items-end">
      <span className="text-end">...</span>
    </div>
  );

  // TODO: fix ellipse on second to last page (12...13 should be 12 13)
  return (
    <div className="flex mt-2 mb-4 w-full justify-center">
      {pages.map((page, i) => (
        <div key={getRandomString()} className="flex">
          {totalPages > 5 && i === 1 && page - 1 !== i ? ellipse : null}
          {totalPages > 5 && i === pages.length - 1 ? ellipse : null}
          <button
            type="button"
            className={classNames(
              pageNumber === Number(page) ? 'bg-opacity-0' : ' bg-opacity-10',
              'bg-theme-blue border-theme-blue border rounded w-8 h-8',
              'hover:bg-white mx-1 border-opacity-10 transition-colors',
            )}
            onClick={onClick}
            value={page}
          >
            {page}
          </button>
        </div>
      ))}
    </div>
  );
}
