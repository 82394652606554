import { Link } from 'react-router-dom';
import Error from './Error';

export default function PageNotFound() {
  return (
    <Error>
      <p>Error: 404 Page Not Found</p>
      <Link to="/" className="text-theme-blue hover:underline">
        Navigate Home
      </Link>
    </Error>
  );
}
