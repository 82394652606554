import { createSlice } from '@reduxjs/toolkit';
import type {
  SearchPayloadLimited,
  SearchState,
} from '../../types/searchTypes';
import type { RootState } from '../../types/commonTypes';

const initialState: SearchState = {
  searchQuery: null,
};

const searchSlice = createSlice({
  name: 'resolution',
  initialState,
  reducers: {
    setSearchQuery(state, action: { payload: SearchPayloadLimited | null }) {
      state.searchQuery = action.payload;
    },
  },
});

export default searchSlice.reducer;

export const { setSearchQuery } = searchSlice.actions;

export const getSearchQuery = (state: RootState) => state.search.searchQuery;
