import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet'; // Import Helmet
import { faChevronDown, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { getSearchQuery, setSearchQuery } from './searchSlice';
import { setShowSignInModal } from '../sso/authSlice';
import { classNames, getIDType, resolveQs } from '../../utils/commonUtils';
import { useResolveQuery } from '../../store';
import { baseQuery, refTypeOptions } from '../../utils/searchUtils';
import useSingleSignOn from '../../hooks/useSingleSignOn';
import ClassicModeLink from '../../components/ClassicModeLink';
import Spinner from '../../components/Spinner';
import Error from '../../components/Error';
import useCurrentQuery from '../../hooks/useCurrentQuery';
import { ReferentType } from '../../types/searchTypes';
import { ILandingPageForm } from '../../types/commonTypes';
import SearchBtn from './SearchBtn';
import { getResolutionId, setResolutionId } from '../resolve/resolveSlice';

function AdvancedSearch() {
  const resolutionId = useAppSelector(getResolutionId);
  const searchQuery = useAppSelector(getSearchQuery);
  const [localResolutionId, setLocalResolutionId] = useState(resolutionId || ' ');
  const [searchOption, setSearchOption] = useState(resolutionId ? 'id' : (searchQuery?.referentType || ''));
  const [searchStr, setSearchStr] = useState(searchQuery?.resourceName?.resourceName || ' ');
  const [isAdvancedSearchReady, setIsAdvancedSearchReady] = useState(false);

  const formSubmitted = useRef(false);

  const sso = useSingleSignOn();
  const dispatch = useAppDispatch();

  const {
    isSuccess: resolveSuccess,
    isLoading: resolveLoading,
    error: resolveError,
  } = useResolveQuery(resolutionId, {
    skip: !resolutionId || !formSubmitted.current,
  });

  const {
    isSuccess: searchSuccess,
    isLoading: searchLoading,
    error: searchError,
  } = useCurrentQuery(!formSubmitted.current);

  const navigate = useNavigate();
  useEffect(() => {
    if (resolveSuccess && resolutionId) {
      navigate(resolveQs(resolutionId));
    }

    if (searchSuccess) {
      navigate('/search/results');
    }
  }, [resolveSuccess, navigate, resolutionId, searchSuccess]);
  useEffect(() => {
    if (resolutionId) {
      setSearchOption('id');
    }
  }, [resolutionId]);
  useEffect(() => {
    if ((searchError as any)?.status === 401) {
      dispatch(setShowSignInModal(true));
    }
  }, [searchError, dispatch]);

  const {
    register,
    handleSubmit,
    formState: {
      errors,
    },
  } = useForm<ILandingPageForm>();
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (searchOption === 'id') {
      setLocalResolutionId(e.target.value);
    } else {
      setSearchStr(e.target.value);
    }
  };
  const handleOptionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSearchOption(e.target.value);
    setLocalResolutionId('');
    setSearchStr('');
    dispatch(setResolutionId({ resolutionId: '' }));
    dispatch(setSearchQuery(null));
  };

  const handleFocus = () => {
    // Check which state variable is currently controlling the input's value
    // and update it accordingly
    if (searchOption === 'id') {
      // If the input is controlled by localResolutionId, trim and update it
      // Assuming there's a setLocalResolutionId setter function
      setLocalResolutionId(localResolutionId.trim());
    } else {
      // If the input is controlled by searchStr, trim and update it
      setSearchStr(searchStr.trim());
    }
  };

  const generateSearchQuery = (referentType: string, searchInput: string) => {
    if (referentType === 'all' || !referentType) {
      return {
        ...baseQuery,
        resourceName: {
          resourceName: searchInput,
          alternateResourceNameAddition: true,
        },
      };
    }
    if (referentType === 'id') {
      return {
        ...baseQuery,
        alternateId: searchInput,
      };
    }
    return {
      ...baseQuery,
      resourceName: {
        resourceName: searchInput,
        alternateResourceNameAddition: true,
      },
      referentType: referentType as ReferentType,
    };
  };

  const onSubmit = async (
    { searchInput, referentType }: ILandingPageForm,
  ) => {
    formSubmitted.current = true; // Set form submitted to true
    if (searchOption === 'id') {
      searchInput = localResolutionId?.trim();
    } else {
      searchInput = searchStr?.trim();
    }
    if (!searchInput) return;
    if (referentType) {
      referentType = referentType.trim();
    }

    if (referentType === 'id') {
      // Handle 'id' referent type
      switch (getIDType(searchInput)) {
        case 'content':
        case 'party':
        case 'video service':
          dispatch(setResolutionId({ resolutionId: searchInput }));
          break;
        default:
          dispatch(setSearchQuery(generateSearchQuery(referentType, searchInput)));
          break;
      }
    } else {
      dispatch(setSearchQuery(generateSearchQuery(referentType, searchInput)));
    }
    // Ensure there's at least one more statement after the if-else block
    console.log('Form submitted');
  };

  useEffect(() => {
    // Logic to determine if advanced search is ready
    // For example, you can check if the search input is not empty
    setIsAdvancedSearchReady(true); // Placeholder logic, replace it with your actual condition
  }, []);

  const placeholderText = () => {
    if (searchOption === 'all') {
      return 'Search for a record by Title or Resolve an ID';
    }
    if (searchOption === 'id') {
      return 'Resolve an EIDR Content ID, Party ID, or Video Service ID';
    }
    if (searchOption === 'series') {
      return 'Limit search results to Series records.';
    }
    if (searchOption === 'season') {
      return 'Limit search results to Season records.';
    }
    if (searchOption === 'TV') {
      return 'Limit search results to Television records.';
    }
    if (searchOption === 'movie') {
      return 'Limit search results to Movie records.';
    }
    if (searchOption === 'short') {
      return 'Limit search results to Short records.';
    }
    if (searchOption === 'web') {
      return 'Limit search results to Web records.';
    }
    if (searchOption === 'supplemental') {
      return 'Limit search results to Supplemental records.';
    }
    return sso ? 'Search registry' : 'Resolve an EIDR Content ID, Party ID, or Video Service ID';
  };

  return (
    <>
      <div className="w-full bg-theme-blue py-4 px-20 text-white text-2xl">
        Advanced Search
      </div>
      <div className="mt-24 flex px-4">
        <Helmet>
          <title>Advanced Search</title>
          <meta name="description" content="Perform an advanced search in the EIDR registry to find content IDs, party IDs, and video service IDs with detailed filtering options." />
          <meta name="keywords" content="EIDR, advanced search, content ID, party ID, video service ID, search registry" />
        </Helmet>
        <div className="w-full flex justify-center">
          <div className="max-w-4xl w-full">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className={classNames(
                'border relative',
                'justify-between flex-1',
                'flex rounded border-theme-gray-200',
              )}
            >
              <div
                className={classNames(
                  sso ? '' : 'hidden',
                  'w-48 h-full border-theme-gray-200 bg-opacity-20',
                  'rounded-l border-r bg-theme-gray-200 relative',
                )}
              >
                <div className="flex relative w-full h-full">
                  <select
                    id="referentType"
                    {...register('referentType')}
                    className={classNames(
                      'rounded-l appearance-none p-3 z-20',
                      'bg-transparent h-full w-full cursor-pointer',
                    )}
                    value={searchOption}
                    onChange={handleOptionChange}
                  >
                    {[
                      { value: 'all', name: 'All' }, // Add 'All' option
                      { value: 'id', name: 'EIDR/Alt ID' },
                      ...refTypeOptions,
                    ].map(({ name, value }) => (
                      <option value={value} key={`refTypeOption-${name}`}>
                        {name}
                      </option>
                    ))}
                  </select>
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    className={classNames(
                      'text-sm absolute right-4 top-1/2 -translate-y-1/2',
                      'text-theme-gray-200',
                    )}
                  />
                </div>
              </div>

              <label htmlFor="searchInput" className="w-full">
                <input
                  {...register('searchInput', {
                    required: { value: true, message: 'This is a required field.' },
                    validate: {
                      checkIdType: (val) => {
                        const idType = getIDType(val);
                        return !(!idType && !sso) || 'Invalid EIDR ID';
                      },
                    },
                  })}
                  value={searchOption === 'id' ? localResolutionId : searchStr}
                  onChange={handleInputChange}
                  onFocus={handleFocus}
                  className={classNames(
                    sso ? 'rounded-r' : 'rounded',
                    'p-3 w-full text-sm md:text-base',
                  )}
                  placeholder={placeholderText()} // Updated line
                />
              </label>
              <div className="h-full flex items-center my-auto absolute right-4">
                {resolveLoading || searchLoading ? (
                  <Spinner />
                ) : (
                  <button type="submit">
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                  </button>
                )}
              </div>
              {!sso && (
                <div className="m-2 text-red-400 absolute top-full">
                  {errors?.searchInput && <p>{errors.searchInput.message}</p>}
                </div>
              )}
            </form>
            {sso ? (
              <div className="mt-2 flex justify-between">
                <div className="flex items-center">
                  <ClassicModeLink />
                  <div className="ml-2 text-red-400 ">
                    {errors?.searchInput && <p>{errors.searchInput.message}</p>}
                  </div>
                </div>
                <SearchBtn isAdvanced />
              </div>
            ) : (
              <div className="flex justify-end mt-2 text-sm md:text-base">
                <button
                  type="button"
                  className="text-theme-blue hover:underline"
                  onClick={() => dispatch(setShowSignInModal(true))}
                >
                  Sign in
                </button>
                <span className="ml-1 text-theme-black">
                  for advanced features
                </span>
              </div>
            )}
            {resolveError ? (
              <Error>
                <p>Oops! We couldn&apos;t find that record, please try again.</p>
              </Error>
            ) : null}

            {searchError && (searchError as any).status !== 401 && (
              <Error>
                <p>Oops! An error occurred, please try again.</p>
              </Error>
            )}

            {isAdvancedSearchReady && (
              <div className="mt-4 text-center text-red-500">
                <p>Advanced Search isn&apos;t ready.</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default AdvancedSearch;
