export interface UserData {
  email: string;
  name: string;
  picture: string;
}

export interface AuthState {
  idp: {
    idToken: string | null;
    provider: ProviderTypes | null;
  };
  showSignInModal: boolean;
}

export enum ProviderTypes {
  Google = 'Google',
  LinkedIn = 'LinkedIn',
}

export type Provider = {
  clientId: string;
  redirectUri: string;
  responseType: string;
  scope: string;
  authorizationEndpoint: string;
};
