export default function EidrButton({ onClick, text }: { onClick: () => void; text: string }) {
  return (
    <button
      type="button"
      onClick={onClick}
      className="flex items-left justify-start gap-3 w-full pr-10 py-2 border-0 rounded shadow text-white cursor-pointer text-lg m-1 overflow-hidden h-14"
      style={{
        display: 'flex', width: '98%', backgroundColor: '#2b3c51', height: '50px',
      }}
    >
      <i
        className="fas fa-sign-in-alt fa-lg"
        style={{
          margin: '8px 0 0 10px ',
        }}
      />
      <span style={{ margin: '2px 0 0 0px ' }}>{text}</span>
    </button>
  );
}
