import { Link } from 'react-router-dom';
import { ILinkInfo } from '../types/commonTypes';

export default function DataLink({ link }: { link: ILinkInfo | undefined }) {
  return (
    <>
      {' '}
      {/** Render anchor link */}
      {link && link?.type === 'a' ? (
        <a
          className="text-theme-blue"
          // rel="noreferrer"
          href={link.to}
          // target="_blank"
        >
          {link.text}
        </a>
      ) : null}
      {/** Render react router link */}
      {link && link?.type === 'link' ? (
        <Link className="text-theme-blue" to={link?.to}>
          {link.text}
        </Link>
      ) : null}
    </>
  );
}
