import { SetStateAction } from 'react';
import { ProviderTypes, Provider, UserData } from '../types/authTypes';
import config from '../config';

export const providerBase = {
  clientId: '',
  authorizationEndpoint: '',
  redirectUri: window.location.origin,
  responseType: 'code',
  scope: 'openid profile email',
};

export const providerMap = new Map<ProviderTypes, Provider>([
  [
    ProviderTypes.Google,
    {
      ...providerBase,
      clientId: config.GOOGLE_CLIENT_ID,
      authorizationEndpoint: config.GOOGLE_AUTH_URL,
    },
  ],
  [
    ProviderTypes.LinkedIn,
    {
      ...providerBase,
      clientId: config.LINKEDIN_CLIENT_ID,
      authorizationEndpoint: config.LINKEDIN_AUTH_URL,
    },
  ],
]);

export const getAuthorizationUrl = (
  authorizationEndpoint: string,
  clientId: string,
  redirectUri: string,
  responseType: string,
  scope: string,
): string => {
  const state = Math.floor(Math.random() * 1000000).toString();
  sessionStorage.setItem('auth_login_state', state);
  const url = `${authorizationEndpoint}?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=${responseType}&scope=${scope}&state=${state}`;
  return url;
};

export const logout = (
  setUser: React.Dispatch<SetStateAction<UserData | null>>,
  setIsAuthenticated: React.Dispatch<SetStateAction<boolean>>,
) => {
  sessionStorage.removeItem('id_token');
  sessionStorage.removeItem('session_idp');
  sessionStorage.removeItem('auth_login_state');
  setUser(null);
  setIsAuthenticated(false);
};
