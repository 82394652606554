import type { EidrIdType, IStackListItem } from '../types/commonTypes';

export const classNames = (...classes: string[]) =>
  classes.filter(Boolean).join(' ');

export const concatUrl = (...paths: string[]) => paths.join('/');

export const resolveQs = (resolutionId: string) =>
  `/view/content?id=${resolutionId}`;

export const copyToClipboard = (string: string) =>
  navigator.clipboard.writeText(string);

export const getGenericStackList = ({ value, ...rest }: any) => {
  const stackList: IStackListItem = { value };

  if (rest) {
    stackList.pipeList = Object.entries(rest).map(([key, itemVal]) => ({
      name: key,
      value: itemVal as string,
    }));
  }

  return stackList;
};

export const getRandomString = () =>
  (Math.random() + 1).toString(36).substring(7);

export const camelToTitleCase = (key: string) =>
  key.replace(/([a-z])([A-Z])/g, '$1 $2');

// prettier-ignore
export const contentRegex = /10.5240\/[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{1}/;
export const partyRegex = /10.5237\/[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}/;
export const serviceRegex = /10.5239\/[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}/;

export const getIDType = (string: string): EidrIdType | null => {
  if (string.match(contentRegex)) {
    return 'content';
  }

  if (string.match(partyRegex)) {
    return 'party';
  }

  if (string.match(serviceRegex)) {
    return 'video service';
  }

  return null;
};
