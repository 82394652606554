import { range } from 'lodash';
import { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { classNames } from '../utils/commonUtils';

const years = range(1950, new Date().getFullYear() + 1).reverse();
const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export default function DateSelect({
  helperText,
  yearPicker,
  handleNewDate,
}: {
  helperText: string;
  yearPicker?: boolean;
  handleNewDate: (newDate: Date | null, yearOnly?: boolean) => void;
}) {
  const [startDate, setStartDate] = useState<Date | null>();
  return (
    <div
      className={classNames(
        'bg-opacity-10 w-min rounded border-theme-gray-200 mr-2',
        'mb-4 flex capitalize items-center border bg-theme-gray-200',
        'hover:bg-opacity-50 mt-2',
      )}
    >
      {yearPicker ? (
        <DatePicker
          className={classNames('pl-2 rounded')}
          selected={startDate}
          onChange={(date) => {
            setStartDate(date);
            handleNewDate(date, true);
          }}
          showYearPicker
          isClearable
          dateFormat="yyyy"
          yearItemNumber={6}
          placeholderText={helperText}
        />
      ) : (
        <DatePicker
          className={classNames('pl-2 rounded')}
          placeholderText={helperText}
          isClearable
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <div>
              <button
                type="button"
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
              >
                {'<'}
              </button>
              <select
                value={date.getFullYear()}
                onChange={({ target: { value } }) => changeYear(+value)}
              >
                {years.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>

              <select
                value={months[date.getMonth()]}
                onChange={({ target: { value } }) =>
                  changeMonth(months.indexOf(value))
                }
              >
                {months.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>

              <button
                type="button"
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
              >
                {'>'}
              </button>
            </div>
          )}
          selected={startDate}
          onChange={(date) => {
            setStartDate(date);
            handleNewDate(date);
          }}
        />
      )}
    </div>
  );
}
