import { Dispatch, SetStateAction } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { EidrLogo } from '../assets/EidrHeaderAssets';
import useSingleSignOn from '../hooks/useSingleSignOn';
import LinkBanner from './LinkBanner';
import config from '../config';
import { classNames } from '../utils/commonUtils';
import useLoginLogout from '../hooks/useLoginLogout';

function MobileMenu({
  setShowMobileMenu,
}: {
  setShowMobileMenu: Dispatch<SetStateAction<boolean>>;
}) {
  const sso = useSingleSignOn();

  const [toggleSignIn, logout] = useLoginLogout();
  return (
    <div
      className={classNames(
        `${config.bannerColor} absolute z-30 bg-blue`,
        'flex flex-col items-center left-0 right-0 py-4 shadow-md',
      )}
    >
      <button
        className="absolute right-3 top-2 text-2xl"
        type="button"
        aria-label="close mobile menu"
        onClick={() => {
          setShowMobileMenu(false);
        }}
      >
        <FontAwesomeIcon icon={faClose} />
      </button>
      <div className="flex items-center mx-auto">
        <EidrLogo />
      </div>
      <h1 className="font-light mt-1 text-base font-roboto">
        Entertainment Identifier Registry
      </h1>
      <div className="flex w-full justify-center bg-theme-gray-100 py-2 mt-4 items-center">
        <div>
          {sso ? (
            <img
              className="rounded-full w-8"
              src={sso.picture}
              alt="User Profile"
            />
          ) : (
            <FontAwesomeIcon
              className="text-theme-blue text-3xl"
              icon={faUserCircle}
            />
          )}
        </div>

        <button
          className="font-semibold text-theme-blue ml-2"
          aria-label="sign in"
          type="button"
          onClick={() => (sso ? logout() : toggleSignIn())}
        >
          {sso ? 'Sign out' : 'Sign in'}
        </button>
      </div>
      <LinkBanner />
    </div>
  );
}

export default MobileMenu;
