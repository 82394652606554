import { configureStore } from '@reduxjs/toolkit';
import resolveSlice from './features/resolve/resolveSlice';
import { apiSlice } from './features/api/apiSlice';
import authSlice from './features/sso/authSlice';
import searchSlice from './features/search/searchSlice';
import navSlice from './features/nav/navSlice';

export const store = configureStore({
  reducer: {
    resolve: resolveSlice,
    authentication: authSlice,
    search: searchSlice,
    nav: navSlice,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleWare) =>
    getDefaultMiddleWare().concat(apiSlice.middleware),
});

export default store;
export const { endpoints, useResolveQuery, useSearchQuery } = apiSlice;
