import config from '../config';
import { classNames } from '../utils/commonUtils';

export default function SandboxOverlay() {
  return (
    <div
      className={classNames(
        config.bannerColor,
        'absolute z-0 right-0 top-1/2 py-12 bg-opacity-25 p-2 capitalize',
        '-translate-y-1/2 border-dashed border-y-4 border-l-4 h-max',
      )}
      style={{ writingMode: 'vertical-rl', textOrientation: 'upright' }}
    >
      {config.envName}
    </div>
  );
}
