import { ChangeEvent } from 'react';

export default function Checkbox({
  name,
  onChange,
  className,
  type = 'checkbox',
  checked = false,
}: {
  name: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  type?: 'checkbox' | 'radio';
  checked?: boolean;
}) {
  return (
    <label htmlFor="referentType" className={className}>
      <input
        type={type}
        name="referentType"
        onChange={onChange ? (e) => onChange(e) : () => null}
        id={name}
        className="mr-2"
        value={name}
        checked={checked}
      />
      {name}
    </label>
  );
}
