import React, { ReactNode } from 'react';
import ExpandableJsonField from '../features/resolve/ExpandableJsonField';

interface ViewJProp {
  request?: object;
  response: object;
  children?: ReactNode;
}

const ViewEJson: React.FC<ViewJProp> = function ViewEJson({ request, response, children }) {
  return (
    <div className="my-2 bg-theme-blue bg-opacity-5 border border-theme-blue border-opacity-20 shadow-md rounded flex flex-col p-4 mb-4">
      {children}
      <div className="font-semibold my-2">
        <ExpandableJsonField
          title="Request"
          canOpen
          contentWhenClosed=" "
          messageOnOpen=" "
          defaultOpen
        >
          <pre className="font-light bg-theme-gray-100 p-2 shadow-md mt-2">
            <div className="text-xs overflow-x-auto break-all">
              {JSON.stringify(request, null, 2)}
            </div>
          </pre>
        </ExpandableJsonField>
      </div>
      <div className="font-semibold my-2">
        <ExpandableJsonField
          title="Response"
          canOpen
          contentWhenClosed=" "
          messageOnOpen=" "
          defaultOpen
        >
          <pre className="font-light bg-theme-gray-100 p-2 shadow-md mt-2">
            <div className="text-xs overflow-x-auto break-all">
              {JSON.stringify(response, null, 2)}
            </div>
          </pre>
        </ExpandableJsonField>
      </div>
    </div>
  );
};

export default ViewEJson;
