import React, { useMemo } from 'react';
import { IContentIdResponse } from '../../types/commonTypes';
import ExpandableField from './ExpandableField';
import BaseObjectMetadata from './BaseObjectMetadata';
import { IContentRecord } from '../../types/resolutionTypes';

function ContentRecord({
  response: { data },
  showXML,
}: {
  response: IContentIdResponse;
  showXML: boolean;
}) {
  const { extraObjectMetadata, provenanceMetadata, ...rest } = useMemo(
    () => (data ? data[0] : ({} as IContentRecord)),
    [data],
  );

  return extraObjectMetadata || provenanceMetadata || rest ? (
    <>
      {rest ? <BaseObjectMetadata record={rest} /> : null}

      {extraObjectMetadata ? (
        <ExpandableField
          title="EXTRA OBJECT METADATA"
          data={extraObjectMetadata}
        />
      ) : null}

      {showXML && (
        <>
          <ExpandableField
            title="SELF DEFINED METADATA"
            data={{}} // Empty data
            canClose
            contentWhenClosed="XML not available"
          />

          <ExpandableField
            title="FULL RESOLUTION METADATA"
            data={{}} // Empty data
            canClose
            contentWhenClosed="XML not available"
          />
        </>
      )}

      {provenanceMetadata ? (
        <ExpandableField
          title="PROVENANCE METADATA"
          data={{ data: provenanceMetadata }}
        />
      ) : null}
    </>
  ) : null;
}

export default ContentRecord;
