import USFlag from './US.svg'; // Adjust the path to the US flag SVG file
import UKFlag from './GB.svg';
import ARFlag from './ar.svg';
import BPFlag from './BR.svg';
import FCFlag from './fr-CA.svg';
import SCFlag from './CN.svg';
import DNFlag from './DK.svg';
import FRFlag from './FR.svg';
import GRFlag from './DE.svg';
import GKFlag from './GR.svg';
import HIFlag from './IN.svg';
import ITFlag from './IT.svg';
import JPFlag from './JP.svg';
import KNFlag from './tlh.svg';
import KRFlag from './KR.svg';
import LatinFlag from './la-2.svg';
import LAFlag from './es-419.svg';
import PTFlag from './PT.svg';
import RUFlag from './RU.svg';
import SPFlag from './ES.svg';
import TKFlag from './TR.svg';
import TCFlag from './HK.svg';

function US() {
  return (
    <div>
      {/* Render the image */}
      <img src={USFlag} alt="US" style={{ marginRight: '0.5rem', width: '24px' }} />
    </div>
  );
}
function AR() {
  return (
    <div>
      {/* Render the image */}
      <img src={ARFlag} alt="AR" style={{ marginRight: '0.5rem', width: '24px' }} />
    </div>
  );
}
function BP() {
  return (
    <div>
      {/* Render the image */}
      <img src={BPFlag} alt="BP" style={{ marginRight: '0.5rem', width: '24px' }} />
    </div>
  );
}
function UK() {
  return (
    <div>
      {/* Render the image */}
      <img src={UKFlag} alt="UK" style={{ marginRight: '0.5rem', width: '24px' }} />
    </div>
  );
}
function FC() {
  return (
    <div>
      {/* Render the image */}
      <img src={FCFlag} alt="FC" style={{ marginRight: '0.5rem', width: '24px' }} />
    </div>
  );
}
function SC() {
  return (
    <div>
      {/* Render the image */}
      <img src={SCFlag} alt="SC" style={{ marginRight: '0.5rem', width: '24px' }} />
    </div>
  );
}
function TC() {
  return (
    <div>
      {/* Render the image */}
      <img src={TCFlag} alt="TC" style={{ marginRight: '0.5rem', width: '24px' }} />
    </div>
  );
}
function DN() {
  return (
    <div>
      {/* Render the image */}
      <img src={DNFlag} alt="DK" style={{ marginRight: '0.5rem', width: '24px' }} />
    </div>
  );
}
function FR() {
  return (
    <div>
      {/* Render the image */}
      <img src={FRFlag} alt="FR" style={{ marginRight: '0.5rem', width: '24px' }} />
    </div>
  );
}
function GR() {
  return (
    <div>
      {/* Render the image */}
      <img src={GRFlag} alt="GR" style={{ marginRight: '0.5rem', width: '24px' }} />
    </div>
  );
}
function GK() {
  return (
    <div>
      {/* Render the image */}
      <img src={GKFlag} alt="GK" style={{ marginRight: '0.5rem', width: '24px' }} />
    </div>
  );
}
function HI() {
  return (
    <div>
      {/* Render the image */}
      <img src={HIFlag} alt="hi" style={{ marginRight: '0.5rem', width: '24px' }} />
    </div>
  );
}
function IT() {
  return (
    <div>
      {/* Render the image */}
      <img src={ITFlag} alt="it" style={{ marginRight: '0.5rem', width: '24px' }} />
    </div>
  );
}
function JP() {
  return (
    <div>
      {/* Render the image */}
      <img src={JPFlag} alt="jp" style={{ marginRight: '0.5rem', width: '24px' }} />
    </div>
  );
}
function KN() {
  return (
    <div>
      {/* Render the image */}
      <img src={KNFlag} alt="kn" style={{ marginRight: '0.5rem', width: '24px' }} />
    </div>
  );
}
function KR() {
  return (
    <div>
      {/* Render the image */}
      <img src={KRFlag} alt="kr" style={{ marginRight: '0.5rem', width: '24px' }} />
    </div>
  );
}
function Latin() {
  return (
    <div>
      {/* Render the image */}
      <img src={LatinFlag} alt="SPQR" style={{ marginRight: '0.5rem', width: '24px' }} />
    </div>
  );
}
function LA() {
  return (
    <div>
      {/* Render the image */}
      <img src={LAFlag} alt="LA" style={{ marginRight: '0.5rem', width: '24px' }} />
    </div>
  );
}
function PT() {
  return (
    <div>
      {/* Render the image */}
      <img src={PTFlag} alt="PT" style={{ marginRight: '0.5rem', width: '24px' }} />
    </div>
  );
}
function RU() {
  return (
    <div>
      {/* Render the image */}
      <img src={RUFlag} alt="RU" style={{ marginRight: '0.5rem', width: '24px' }} />
    </div>
  );
}
function SP() {
  return (
    <div>
      {/* Render the image */}
      <img src={SPFlag} alt="ES" style={{ marginRight: '0.5rem', width: '24px' }} />
    </div>
  );
}
function TK() {
  return (
    <div>
      {/* Render the image */}
      <img src={TKFlag} alt="TK" style={{ marginRight: '0.5rem', width: '24px' }} />
    </div>
  );
}
export {
  US,
  AR,
  UK,
  BP,
  FC,
  SC,
  DN,
  FR,
  GR,
  GK,
  HI,
  IT,
  JP,
  KN,
  KR,
  Latin,
  LA,
  PT,
  RU,
  SP,
  TK,
  TC,
};
