import { useState } from 'react';
import {
  GoogleLoginButton,
  LinkedInLoginButton,
} from 'react-social-login-buttons';
import { ProviderTypes } from '../../types/authTypes';
import { useAppDispatch } from '../../hooks/reduxHooks';
import { setIdp } from './authSlice';
import useLoginLogout from '../../hooks/useLoginLogout';
import EidrButton from '../../components/EidrButton';
import EidrLoginModal from './EidrLoginModal';

export default function SocialLogins() {
  const dispatch = useAppDispatch();
  const [, logout] = useLoginLogout();
  const [showEidrLoginModal, setShowEidrLoginModal] = useState(false);
  const handleClick = async (idp: ProviderTypes) => {
    logout();
    dispatch(setIdp({ idp }));
  };
  const handleEidrClick = () => {
    setShowEidrLoginModal(true);
  };
  const handleCloseEidrModal = () => {
    setShowEidrLoginModal(false);
  };

  return (
    <>
      <div>
        <GoogleLoginButton onClick={() => handleClick(ProviderTypes.Google)} />
        <LinkedInLoginButton onClick={() => handleClick(ProviderTypes.LinkedIn)} />
        <EidrButton onClick={handleEidrClick} text="Sign in with EIDR Credentials" />
      </div>
      {showEidrLoginModal && <EidrLoginModal onClose={handleCloseEidrModal} />}
    </>
  );
}
