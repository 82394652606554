import React, { useState } from 'react';
import parse from 'html-react-parser';
import {
  US,
  AR,
  UK,
  BP,
  FC,
  SC,
  DN,
  FR,
  GR,
  GK,
  HI,
  IT,
  JP,
  KN,
  KR,
  Latin,
  LA,
  PT,
  RU,
  SP,
  TK,
  TC,
} from '../assets/FlagAssets';

interface LanguageChoice {
  code: string;
  name: string;
  englishAsk: string;
  localAsk: string;
  localEmailUs: string;
  emailUs: string;
}

const languages: LanguageChoice[] = [
  {
    code: 'en',
    name: 'English | English',
    englishAsk: '',
    localAsk: '',
    localEmailUs: '',
    emailUs: '',
  },
  {
    code: 'ar',
    name: 'Arabic | العربية',
    englishAsk: 'Arabic is not available. Please contact us if you can help us with the translation.',
    localAsk: 'اللغة العربية غير متوفرة. يرجى الاتصال بنا إذا كنت تستطيع مساعدتنا في الترجمة. <مراسلتنا عبر ا',
    localEmailUs: ' Email us',
    emailUs: 'مراسلتنا عبر البريد الإلكتروني',
  },
  {
    code: 'zh-CN',
    name: 'Chinese (Simplified) | 中国人',
    englishAsk: 'Chinese is not available. Please contact us if you can help us with the translation.',
    localAsk: '中文不可用。 如果您可以帮助我们翻译，请联系我们。',
    localEmailUs: 'Email us',
    emailUs: '给我们发电子邮件',
  },
  {
    code: 'zh-TW',
    name: 'Chinese (Traditional) | 中文',
    englishAsk: 'Chinese is not available. Please contact us if you can help us with the translation.',
    localAsk: '中文不可用。 如果您可以幫助我們翻譯，請與我們聯絡。',
    localEmailUs: 'Email us',
    emailUs: '給我們發電子郵件',
  },
  {
    code: 'da',
    name: 'Danish | Dansk',
    englishAsk: 'Danish is not available. Please contact us if you can help us with the translation.',
    localAsk: 'Dansk findes ikke. Kontakt os venligst, hvis du kan hjælpe os med oversættelsen.',
    localEmailUs: 'Email us',
    emailUs: 'Send os en e-mail',
  },
  {
    code: 'en-GB',
    name: 'British English | English',
    englishAsk: 'British English is not available. Please contact us if you can help us with the translation.',
    localAsk: 'Deepest regrets, but the King\'s English is not amongst those currently on offer. We\'d be ever so chuffed if you could aassist us with all the fiddly bits of a bespoke translation.',
    localEmailUs: 'Email us',
    emailUs: 'Ring Us Up ',
  },
  {
    code: 'fr',
    name: 'French | Française',
    englishAsk: 'French is not available. Please contact us if you can help us with the translation.',
    localAsk: 'Le français n\'est pas disponible. Veuillez nous contacter si vous pouvez nous aider avec la traduction. ',
    localEmailUs: 'Email us',
    emailUs: 'Envoyez-nous un email',
  },
  {
    code: 'fr-CA',
    name: 'French (Canada) | Québécois',
    englishAsk: 'Canadian French is not available. Please contact us if you can help us with the translation.',
    localAsk: 'Le Québécois n’est pas disponible. S’il vous plaît contactez-nous si vous pouvez nous aider avec la traduction.',
    localEmailUs: 'Email us',
    emailUs: 'Envoyez-nous un courrie',
  },
  {
    code: 'de',
    name: 'German | Deutsch',
    englishAsk: 'German is not available. Please contact us if you can help us with the translation.',
    localAsk: 'Deutsch ist nicht verfügbar. Bitte kontaktieren Sie uns, wenn Sie uns bei der Übersetzung helfen können.',
    localEmailUs: 'Email us',
    emailUs: 'Schreiben Sie uns eine E-Mail',
  },
  {
    code: 'el',
    name: 'Greek | Ελληνικα',
    englishAsk: 'Greek is not available. Please contact us if you can help us with the translation.',
    localAsk: 'Τα ελληνικά δεν είναι διαθέσιμα. Επικοινωνήστε μαζί μας εάν μπορείτε να μας βοηθήσετε με τη μετάφραση.',
    localEmailUs: 'Email us',
    emailUs: 'Στείλτε μας email',
  },
  {
    code: 'hi',
    name: 'Hindi | हिंदी',
    englishAsk: 'Hindi is not available. Please contact us if you can help us with the translation.',
    localAsk: 'हिंदी उपलब्ध नहीं है. यदि आप अनुवाद में हमारी सहायता कर सकते हैं तो कृपया हमसे संपर्क करें।',
    localEmailUs: 'Email us',
    emailUs: 'हमें ईमेल करें',
  },
  {
    code: 'it',
    name: 'Italian | Italiano',
    englishAsk: 'Italian is not available. Please contact us if you can help us with the translation.',
    localAsk: 'L\'italiano non è disponibile. Vi preghiamo di contattarci se potete aiutarci con la traduzione.',
    localEmailUs: 'Email us',
    emailUs: 'Mandaci una email',
  },
  {
    code: 'ja',
    name: 'Japanese | 日本語',
    englishAsk: 'Japanese is not available. Please contact us if you can help us with the translation.',
    localAsk: '日本語は対応しておりません。 翻訳にご協力いただける方はぜひご連絡ください。',
    localEmailUs: 'Email us',
    emailUs: '私達に電子メールを送り',
  },
  {
    code: 'tlh',
    name: 'Klingon | tlhIngan',
    englishAsk: 'Klingon is not available. Please contact us if you can help us with the translation.',
    localAsk: 'nguvmoH tlhInganpu. NuQuchlaHmeH ghu\'vam luqenglaHchu\'chugh.',
    localEmailUs: 'Email us',
    emailUs: 'Qibv',
  },
  {
    code: 'ko',
    name: 'Korean | 한국어',
    englishAsk: 'Korean is not available. Please contact us if you can help us with the translation.',
    localAsk: '한국어는 지원되지 않습니다. 번역에 도움을 주실 수 있는 분은 연락주시기 바랍니다.',
    localEmailUs: 'Email us',
    emailUs: '이메일을 보내주세요',
  },
  {
    code: 'la',
    name: 'Latin | Latina',
    englishAsk: 'Latin is not available. Please contact us if you can help us with the translation.',
    localAsk: 'Latin praesto non est. Contactus nobis sis, si nos potes adiuva nos translatione.',
    localEmailUs: 'Email us',
    emailUs: 'Inscriptio Us',
  },
  {
    code: 'pt',
    name: 'Portuguese | Portoghese',
    englishAsk: 'Portuguese is not available. Please contact us if you can help us with the translation.',
    localAsk: 'Il portoghese non è disponibile. Vi preghiamo di contattarci se potete aiutarci con la traduzione.',
    localEmailUs: 'Email us',
    emailUs: 'Envie-nos um e-mail',
  },
  {
    code: 'pt-BR',
    name: 'Portuguese (Brazil) | Portoghese Brasiliano',
    englishAsk: 'Brazilian Portuguese is not available. Please contact us if you can help us with the translation.',
    localAsk: 'Português do Brasil não está disponível. Entre em contato conosco se puder nos ajudar com a tradução. ',
    localEmailUs: 'Email us',
    emailUs: 'Envie-nos um e-mail',
  },
  {
    code: 'ru',
    name: 'Russian | Pусски',
    englishAsk: 'Russian is not available. Please contact us if you can help us with the translation.',
    localAsk: 'Русский язык недоступен. Пожалуйста, свяжитесь с нами, если вы можете помочь нам с переводом. ',
    localEmailUs: 'Email us',
    emailUs: 'Напишите нам',
  },
  {
    code: 'es',
    name: 'Spanish | Español',
    englishAsk: 'Spanish is not available. Please contact us if you can help us with the translation.',
    localAsk: 'El español no está disponible. Por favor contáctanos si puedes ayudarnos con la traducción.',
    localEmailUs: 'Email us',
    emailUs: 'Envíanos un correo electrónico',
  },
  {
    code: 'es-419',
    name: 'Spanish (Latin America) | Española latinoamericana',
    englishAsk: 'Latin American Spanish is not available. Please contact us if you can help us with the translation.',
    localAsk: 'El español latinoamericano no está disponible. Por favor contáctanos si puedes ayudarnos con la traducción.',
    localEmailUs: 'Email us',
    emailUs: 'Envíanos un correo electrónico',
  },
  {
    code: 'tr',
    name: 'Turkish | Türkçe',
    englishAsk: 'Turkish is not available. Please contact us if you can help us with the translation.',
    localAsk: 'Türkçe mevcut değil. Çeviri konusunda bize yardımcı olabilecekseniz lütfen bizimle iletişime geçin. ',
    localEmailUs: 'Email us',
    emailUs: 'Bize e-posta gönderin',
  },
  // More language objects...
];

function Language() {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedLanguage, setSelectedLanguage] = useState<LanguageChoice | undefined>(
    languages.find((language) => language.code === 'en'),
  );

  const handleLanguageSelection = (language: LanguageChoice) => {
    setSelectedLanguage(language);
    if (language.code !== 'en') {
      setShowModal(true);
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const getMailtoLink = (subject: string) => `mailto:support@eidr.org?subject=${encodeURIComponent(subject)} Translation Assistance`;

  return (
    <div>
      <div className="w-full bg-theme-blue py-4 px-20 text-white text-2xl">Select Language</div>
      <div className="max-w-2xl px-20 py-2">
        {languages.map((language) => (
          <div key={language.code} style={{ marginBottom: '5px' }}>
            <label style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  type="radio"
                  name="language"
                  checked={selectedLanguage && selectedLanguage.code === language.code}
                  onChange={() => handleLanguageSelection(language)}
                  style={{ marginRight: '10px' }} // Adjust the margin as needed
                />
                {language.code === 'en' && <div style={{ marginRight: '5px' }}><US /></div>}
                {language.code === 'ar' && <div style={{ marginRight: '5px' }}><AR /></div>}
                {language.code === 'zh-CN' && <div style={{ marginRight: '5px' }}><SC /></div>}
                {language.code === 'zh-TW' && <div style={{ marginRight: '5px' }}><TC /></div>}
                {language.code === 'da' && <div style={{ marginRight: '5px' }}><DN /></div>}
                {language.code === 'en-GB' && <div style={{ marginRight: '5px' }}><UK /></div>}
                {language.code === 'fr' && <div style={{ marginRight: '5px' }}><FR /></div>}
                {language.code === 'fr-CA' && <div style={{ marginRight: '5px' }}><FC /></div>}
                {language.code === 'de' && <div style={{ marginRight: '5px' }}><GR /></div>}
                {language.code === 'el' && <div style={{ marginRight: '5px' }}><GK /></div>}
                {language.code === 'hi' && <div style={{ marginRight: '5px' }}><HI /></div>}
                {language.code === 'it' && <div style={{ marginRight: '5px' }}><IT /></div>}
                {language.code === 'ja' && <div style={{ marginRight: '5px' }}><JP /></div>}
                {language.code === 'tlh' && <div style={{ marginRight: '5px' }}><KN /></div>}
                {language.code === 'ko' && <div style={{ marginRight: '5px' }}><KR /></div>}
                {language.code === 'la' && <div style={{ marginRight: '5px' }}><Latin /></div>}
                {language.code === 'pt' && <div style={{ marginRight: '5px' }}><PT /></div>}
                {language.code === 'pt-BR' && <div style={{ marginRight: '5px' }}><BP /></div>}
                {language.code === 'ru' && <div style={{ marginRight: '5px' }}><RU /></div>}
                {language.code === 'es' && <div style={{ marginRight: '5px' }}><SP /></div>}
                {language.code === 'es-419' && <div style={{ marginRight: '5px' }}><LA /></div>}
                {language.code === 'tr' && <div style={{ marginRight: '5px' }}><TK /></div>}
                <span>{language.name}</span>
              </div>
            </label>
          </div>
        ))}
      </div>
      {selectedLanguage && showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-gray-100 p-8 rounded-lg max-w-md w-full">
            <div className="text-sm bg-gradient-to-r from-theme-grey-100 p-10 to-transparent">
              <h2 className="text-lg font-semibold mb-4">Selected Language Not Available</h2>
              <div>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
                  <p style={{
                    margin: 0,
                    gap: '2px',
                    whiteSpace: 'pre-line',
                    maxWidth: '45ch',
                  }}
                  >
                    {parse(selectedLanguage.englishAsk)}
                    <a href={getMailtoLink(selectedLanguage.name)} style={{ textDecoration: 'underline', color: 'blue', marginLeft: '10px' }}>
                      {selectedLanguage.localEmailUs}
                    </a>
                  </p>
                </div>
                <hr style={{ margin: '20px 0', border: 'none', borderTop: '2px solid black' }} />
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                  <p style={{ margin: 0, maxWidth: '45ch' }}>
                    {parse(selectedLanguage.localAsk)}
                    <a href={getMailtoLink(selectedLanguage.name)} style={{ textDecoration: 'underline', color: 'blue', marginLeft: '10px' }}>
                      {selectedLanguage.emailUs}
                    </a>
                  </p>
                </div>
              </div>
              <div className="mt-4 flex justify-center">
                <button
                  className="hover:bg-opacity-80 transition-colors text-white bg-theme-blue py-2 px-4 text-sm font-medium rounded"
                  type="button"
                  onClick={handleModalClose}
                  style={{
                    padding: '10px 20px',
                    backgroundColor: '#0077c0',
                    color: 'white',
                  }}
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Language;
