import { classNames } from '../utils/commonUtils';

export default function Spinner() {
  return (
    <span
      className={classNames(
        'w-8 h-8 border-4 border-b-theme-blue',
        'border-white rounded-[50%] animate-spin',
      )}
    />
  );
}
