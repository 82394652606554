import Select, { MultiValue } from 'react-select';
import { Dispatch, SetStateAction, useState } from 'react';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Checkbox from '../../components/Checkbox';
import DateSelect from '../../components/DateSelect';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { countryOptions, languageOptions } from '../../utils/searchUtils';
import { getSearchQuery, setSearchQuery } from './searchSlice';
import { classNames, getRandomString } from '../../utils/commonUtils';

function RefineSearch({
  setFilterOpen,
}: {
  setFilterOpen?: Dispatch<SetStateAction<boolean>>;
}) {
  const dispatch = useAppDispatch();
  const searchQuery = useAppSelector(getSearchQuery);
  const [allChecked, setAllChecked] = useState(false);
  const [yearPickerMode, setYearPickerMode] = useState(false);
  const [includeUND, setIncludeUND] = useState<boolean>(true);
  const [includeXX, setIncludeXX] = useState<boolean>(true); // Default to checked

  const handleCountryChange = (selectedOptions: MultiValue<{ value: string; label: string }>) => {
    const countries = selectedOptions ? selectedOptions.map((option) => option.value) : [];
    let updatedCountries: string[] = [];

    if (countries.length > 0) {
      updatedCountries = includeXX ? [...countries, 'XX'] : countries.filter(country => country !== 'XX');
    }
    
    dispatch(
      setSearchQuery({
        ...searchQuery,
        countryOfOrigin: updatedCountries,
      })
    );
  };

  const handleIncludeXXChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setIncludeXX(checked);

    const { countryOfOrigin = [] } = searchQuery || {};

    if (countryOfOrigin.length > 0) {
      const updatedCountries = checked
      ? [...countryOfOrigin, 'XX']
      : countryOfOrigin.filter(country => country !== 'XX');

      dispatch(
        setSearchQuery({
          ...searchQuery,
          countryOfOrigin: updatedCountries,
        })
      );
    }
    
  };

  const handleLanguageChange = (selectedOptions: MultiValue<{ value: string; label: string }>) => {
    const languages = selectedOptions ? selectedOptions.map((option) => option.value) : [];
    let updatedLanguages: string[] = [];

    if (languages.length > 0) {
      updatedLanguages = includeXX ? [...languages, 'und'] : languages.filter(language => language !== 'und');
    }
   
    dispatch(
      setSearchQuery({
        ...searchQuery,
        originalLanguages: updatedLanguages,
      })
    );
  };

  const handleIncludeUNDChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setIncludeUND(checked);

    const { originalLanguages = [] } = searchQuery || {};

    if (originalLanguages.length > 0) {
      const updatedLanguages = checked
      ? [...originalLanguages, 'und']
      : originalLanguages.filter(language => language !== 'und');

      dispatch(
        setSearchQuery({
          ...searchQuery,
          originalLanguages: updatedLanguages,
        })
      );
    }
  };

  const handleStartDateChange = (date: Date | null, yearOnly?: boolean) => {
    if (searchQuery) {
      const updatedSearchQuery = {
        ...searchQuery,
        releaseDateFrom: yearOnly
          ? date?.getFullYear().toString()
          : date?.toISOString().split('T')[0], // YYYY-MM-DD
      };
      dispatch(setSearchQuery(updatedSearchQuery));
    }
  };

  const handleEndDateChange = (date: Date | null, yearOnly?: boolean) => {
    if (searchQuery) {
      const updatedSearchQuery = {
        ...searchQuery,
        releaseDateTo: yearOnly
          ? date?.getFullYear().toString()
          : date?.toISOString().split('T')[0], // YYYY-MM-DD
      };
      dispatch(setSearchQuery(updatedSearchQuery));
    }
  };

  const toggleAllCheckboxes = () => {
    const newCheckedState = !allChecked;
    setAllChecked(newCheckedState);

    if (searchQuery) {
      let updatedSearchQuery;
      if (newCheckedState) {
        updatedSearchQuery = {
          ...searchQuery,
          filters: {
            rootTitleRecords: newCheckedState,
            compilations: newCheckedState,
            seasons: newCheckedState,
            manifestations: newCheckedState,
            series: newCheckedState,
            episodes: newCheckedState,
            editsClips: newCheckedState,
          }
        }
      } else {
        updatedSearchQuery = Object.fromEntries(Object.entries(searchQuery).filter(([k]) => k !== "filters"))
      }
      
      dispatch(setSearchQuery(updatedSearchQuery));
    };
  }

  const closeFilter = () => (setFilterOpen ? setFilterOpen(false) : null);

  // TODO: Can this be refined?
  // ? Maybe use immer functionality better?
  // ? Maybe use bracket notation in a clever way?
  const filterContent = [
    {
      name: 'exact matches',
      onChange: () =>
        dispatch(
          setSearchQuery({
            ...searchQuery,
            pageNumber: 1,
            resourceName: {
              ...searchQuery?.resourceName,
              exactResourceNameMatch:
                !searchQuery?.resourceName?.exactResourceNameMatch,
            },
          }),
        ),
      checked: searchQuery?.resourceName?.exactResourceNameMatch ?? false,
    },
    {
      name: 'include alternates',
      onChange: () => {
        dispatch(
          setSearchQuery({
            ...searchQuery,
            pageNumber: 1,
            resourceName: {
              ...searchQuery?.resourceName,
              alternateResourceNameAddition:
                !searchQuery?.resourceName?.alternateResourceNameAddition,
            },
          }),
        );
      },
      checked: searchQuery?.resourceName?.alternateResourceNameAddition ?? false,
    },
  ];
  const filters = [
    {
      name: 'root title records',
      onChange: () =>{
        let newFilters = {};
        // Toggle Value
        if (!searchQuery?.filters?.rootTitleRecords) {
          // Was False - Now True
          newFilters = {
            ...searchQuery?.filters,
            "rootTitleRecords": true,
          };
        } else {
          newFilters = Object.fromEntries(Object.entries(searchQuery?.filters).filter(([k]) => k !== "rootTitleRecords"))
        }
        dispatch(
          setSearchQuery({
            ...searchQuery,
            pageNumber: 1,
            filters: newFilters,
          }),
        )
      },
      checked: searchQuery?.filters?.rootTitleRecords ?? false,
    },
    {
      name: 'compilations',
      onChange: () =>{
        let newFilters = {};
        // Toggle Value
        if (!searchQuery?.filters?.compilations) {
          // Was False - Now True
          newFilters = {
            ...searchQuery?.filters,
            "compilations": true,
          };
        } else {
          newFilters = Object.fromEntries(Object.entries(searchQuery?.filters).filter(([k]) => k !== "compilations"))
        }
        dispatch(
          setSearchQuery({
            ...searchQuery,
            pageNumber: 1,
            filters: newFilters,
          }),
        )
      },
      checked: searchQuery?.filters?.compilations ?? false,
    },
    {
      name: 'seasons',
      onChange: () =>{
        let newFilters = {};
        // Toggle Value
        if (!searchQuery?.filters?.seasons) {
          // Was False - Now True
          newFilters = {
            ...searchQuery?.filters,
            "seasons": true,
          };
        } else {
          newFilters = Object.fromEntries(Object.entries(searchQuery?.filters).filter(([k]) => k !== "seasons"))
        }
        dispatch(
          setSearchQuery({
            ...searchQuery,
            pageNumber: 1,
            filters: newFilters,
          }),
        )
      },
      checked: searchQuery?.filters?.seasons ?? false,
    },
    {
      name: 'manifestations',
      onChange: () =>{
        let newFilters = {};
        // Toggle Value
        if (!searchQuery?.filters?.manifestations) {
          // Was False - Now True
          newFilters = {
            ...searchQuery?.filters,
            "manifestations": true,
          };
        } else {
          newFilters = Object.fromEntries(Object.entries(searchQuery?.filters).filter(([k]) => k !== "manifestations"))
        }
        dispatch(
          setSearchQuery({
            ...searchQuery,
            pageNumber: 1,
            filters: newFilters,
          }),
        )
      },
      checked: searchQuery?.filters?.manifestations ?? false,
    },
    {
      name: 'series',
      onChange: () =>{
        let newFilters = {};
        // Toggle Value
        if (!searchQuery?.filters?.series) {
          // Was False - Now True
          newFilters = {
            ...searchQuery?.filters,
            "series": true,
          };
        } else {
          newFilters = Object.fromEntries(Object.entries(searchQuery?.filters).filter(([k]) => k !== "series"))
        }
        dispatch(
          setSearchQuery({
            ...searchQuery,
            pageNumber: 1,
            filters: newFilters,
          }),
        )
      },
      checked: searchQuery?.filters?.series ?? false,
    },
    {
      name: 'episodes',
      onChange: () =>{
        let newFilters = {};
        // Toggle Value
        if (!searchQuery?.filters?.episodes) {
          // Was False - Now True
          newFilters = {
            ...searchQuery?.filters,
            "episodes": true,
          };
        } else {
          newFilters = Object.fromEntries(Object.entries(searchQuery?.filters).filter(([k]) => k !== "episodes"))
        }
        dispatch(
          setSearchQuery({
            ...searchQuery,
            pageNumber: 1,
            filters: newFilters,
          }),
        )
      },
      checked: searchQuery?.filters?.episodes ?? false,
    },
    {
      name: 'edits & clips',
      onChange: () =>{
        let newFilters = {};
        // Toggle Value
        if (!searchQuery?.filters?.editsClips) {
          // Was False - Now True
          newFilters = {
            ...searchQuery?.filters,
            "editsClips": true,
          };
        } else {
          newFilters = Object.fromEntries(Object.entries(searchQuery?.filters).filter(([k]) => k !== "editsClips"))
        }
        dispatch(
          setSearchQuery({
            ...searchQuery,
            pageNumber: 1,
            filters: newFilters,
          }),
        )
      },
      checked: searchQuery?.filters?.editsClips ?? false,
    },
  ];

  return (
    <div
      className={classNames(
        'z-20 absolute lg:static lg:mr-6 mb-4 lg:max-w-xs',
        'left-0 right-0 top-0 bottom-0 flex flex-col',
      )}
    >
      <div className="flex lg:hidden w-full p-4 bg-theme-blue text-white justify-between flex-shrink">
        <button type="button" onClick={closeFilter}>
          <FontAwesomeIcon icon={faChevronLeft} />
          <span className="ml-2 hover:underline">REFINE SEARCH</span>
        </button>
        <button
          type="button"
          onClick={closeFilter}
          className="px-3 py-2 bg-white text-theme-blue rounded hover:underline"
        >
          See Results
        </button>
      </div>
      <div className="bg-theme-gray-100 w-full flex flex-col items-center py-8 rounded px-2 flex-grow">
        <div className="lg:w-min">
          <span className="text-xl font-semibold text-theme-gray-300 mb-2">
            REFINE SEARCH
          </span>
          <div className="flex flex-col gap-2 text-base my-2 w-full">
            <span className="font-bold">Filter Content</span>
            {filters.map((item) => (
              <Checkbox
                key={getRandomString()}
                name={item.name}
                className="capitalize"
                checked={item.checked}
                onChange={item.onChange}
              />
            ))}
            <button
              type="button"
              onClick={toggleAllCheckboxes}
              className="border border-blue-500 text-blue-500 font-bold py-2 px-4 rounded hover:bg-blue-500 hover:text-white transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              {allChecked ? 'Deselect All' : 'Select All'}
            </button>
          </div>
          <div className="my-2">
            <div className="grid grid-flow-row gap-2">
              <span className="font-bold">Filter Titles</span>
              {filterContent.map((item) => (
                <Checkbox
                  key={getRandomString()}
                  name={item.name}
                  className="capitalize"
                  checked={item.checked}
                  onChange={item.onChange}
                />
              ))}
            </div>
          </div>
          <div className="flex flex-col gap-2 text-base my-2 w-full">
            <span className="font-bold">Country of Origin</span>
            <Select
              isMulti
              name="country of origin"
              options={countryOptions}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={handleCountryChange}
            />
            <Checkbox
              name="Include XX"
              checked={includeXX}
              onChange={handleIncludeXXChange}
            />
          </div>
          <div className="flex flex-col gap-2 text-base my-2 w-full">
            <span className="font-bold">Original Language</span>
            <Select
              isMulti
              name="original language"
              options={languageOptions}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={handleLanguageChange}
            />
            <Checkbox
              name="Include und"
              checked={includeUND}
              onChange={handleIncludeUNDChange}
            />
          </div>
          <div className="my-2 font-bold max-w-full">
            <p>Release Date</p>
            <div>
              <DateSelect
                helperText="From"
                yearPicker={yearPickerMode}
                handleNewDate={handleStartDateChange}
              />
              <DateSelect
                helperText="To"
                yearPicker={yearPickerMode}
                handleNewDate={handleEndDateChange}
              />
            </div>

            <div className="flex flex-wrap justify-between font-medium">
              <Checkbox
                name="Year only"
                onChange={() => setYearPickerMode(!yearPickerMode)}
                checked={yearPickerMode}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RefineSearch;
