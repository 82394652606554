import { IPipeListFields } from '../types/commonTypes';
import DataLink from './DataLink';
import { getRandomString } from '../utils/commonUtils';

export default function PipeList({ fields }: { fields: IPipeListFields[] }) {
  return (
    <dl className="subdata max-w-full md:flex-wrap flex-col md:flex-row">
      {fields.map(({ value, link, name }, i) => {
        const key = getRandomString();
        if (!value && !link?.text) return null;
        return (
          <div key={key} className="flex mt-1">
            {/** Render <dt> & <dd> if name and value provided */}
            {value && name && !link ? (
              <div className="flex w-max">
                <dt className="mr-1">{`${name}: `}</dt>
                <dd>{value}</dd>
              </div>
            ) : null}

            {/** Render a (bold) span if just a value is provided */}
            {!name && !link && value ? (
              <span className="font-semibold text-sm text-black">{value}</span>
            ) : null}

            {/** Render a label (name) and link */}
            {link && name && link.text ? (
              <>
                <dt className="mr-1">{`${name}: `}</dt>
                <dd>
                  <DataLink link={link} />
                </dd>
              </>
            ) : null}

            {/** Render a link, no name or value provided */}
            {link && link.text && !name && !value ? (
              <DataLink link={link} />
            ) : null}

            {/** Add a pipe if there is a next item */}
            {fields[i + 1] ? (
              <div className="mx-2 hidden md:block">{' | '}</div>
            ) : null}
          </div>
        );
      })}
    </dl>
  );
}
