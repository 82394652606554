import React from 'react';
import { FaHome, FaEnvelope } from 'react-icons/fa';
import {
  Twitter,
  LinkedInLogo,
  DOI,
  P,
} from '../assets/EidrHeaderAssets';
import config from '../config';
import SlalomLogo from '../assets/slalom.svg';
import { useAppSelector } from '../hooks/reduxHooks';
import { getIdToken } from '../features/sso/authSlice';

function EidrFooter() {
  const token = useAppSelector(getIdToken);
  const isLoggedIn = !!token; // Check if a token exists to determine if the user is logged in

  const handleEmailClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault(); // Prevent the default link behavior
    const subject = 'Web UI Support Question';
    const encodedSubject = encodeURIComponent(subject);
    const mailtoLink = `mailto:support@eidr.org?subject=${encodedSubject}`;
    window.location.href = mailtoLink;
  };

  const getYear = () => {
    const now = new Date();
    return now.getFullYear();
  };

  return (
    <footer className="w-full bg-theme-blue text-white py-4 md:py-2">
      <div className="flex flex-col md:flex-row items-center justify-between px-4 md:px-8 w-full">
        <div className="flex items-center space-x-2 md:space-x-4">
          <a href="#email" onClick={handleEmailClick} className="flex items-center">
            <FaEnvelope size="1.4rem" />
          </a>
          <a href={config.EIDR_TWITTER_URL} target="_blank" rel="noreferrer" className="flex items-center">
            <Twitter />
          </a>
          <a href={config.EIDR_LINKEDIN_URL} target="_blank" rel="noreferrer" className="flex items-center">
            <LinkedInLogo />
          </a>
          <a href="https://www.eidr.org/" target="_blank" rel="noreferrer" className="flex items-center">
            <FaHome size="2.0rem" />
          </a>
          <a href="https://www.doi.org/" target="_blank" rel="noreferrer" className="flex items-center ml-1">
            <DOI />
          </a>
          <span className="text-sm m-1">Powered by</span>
          <a href="https://www.slalom.com/" target="_blank" rel="noreferrer" className="flex items-center ml-1">
            <img src={SlalomLogo} alt="Slalom Consulting Logo" className="h-4 mr-1" />
          </a>
          <a href="https://parivedasolutions.com/" target="_blank" rel="noreferrer" className="flex items-center ml-1">
            <P />
          </a>
        </div>
        <div className="flex items-center justify-center flex-1">
          <p className="text-sm text-center">
            ©
            <span className="hidden md:inline">{getYear()}</span>
            {' '}
            Entertainment ID Registry Association (EIDR).
          </p>
        </div>
        <div className="flex items-center md:text-right">
          <p className="text-sm">
            {isLoggedIn ? 'resolve1.eidr.org ' : 'registry1.eidr.org '}
            v2.6.1
          </p>
        </div>
      </div>
    </footer>
  );
}

export default EidrFooter;
