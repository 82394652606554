// eslint-disable-next-line
import { defaultConfig, dev, endpoints, local, mock, qa } from '../config';
import { AppConfig } from '../types/appConfig';
import { concatUrl } from './commonUtils';

export const getEnvConfig = () => {
  switch (process.env.REACT_APP_STAGE) {
    case 'mock':
      return { ...defaultConfig, ...mock };
    case 'qa':
      return { ...defaultConfig, ...qa };
    case 'local':
      return { ...defaultConfig, ...local };
    default:
      return { ...defaultConfig, ...dev };
  }
};

export const composeConfig = (): AppConfig => {
  const envConfig = getEnvConfig();

  Object.entries(endpoints).forEach(([version, pathList]) => {
    pathList.forEach((path) => {
      // @ts-ignore
      envConfig[path] = concatUrl(version, path);
    });
  });

  return envConfig as AppConfig;
};
