import { IRowData } from '../types/commonTypes';
import { getRandomString } from '../utils/commonUtils';
import TableRow from './TableRow';

export default function DataTable({
  tableRowData,
}: {
  tableRowData: IRowData[];
}) {
  return (
    <table className="w-full">
      <tbody>
        {tableRowData.map((rowData) => (
          <TableRow key={getRandomString()} {...rowData} />
        ))}
      </tbody>
    </table>
  );
}
