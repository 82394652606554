import React, { useEffect, useMemo, useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { faChevronLeft, faFilter, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import RefineSearch from './RefineSearch';
import ClassicModeLink from '../../components/ClassicModeLink';
import RecordCard from './RecordCard';
import SearchBtn from './SearchBtn';
import SortAndOrder from './SortAndOrder';
import Pagination from './Pagination';
import useCurrentQuery from '../../hooks/useCurrentQuery';
import Error from '../../components/Error';
import config from '../../config';
import ViewJson from '../../components/ViewJson';
import { classNames, concatUrl, getRandomString } from '../../utils/commonUtils';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { getSearchQuery, setSearchQuery } from './searchSlice';
import Spinner from '../../components/Spinner';
import useSingleSignOn from '../../hooks/useSingleSignOn';
import { IResultsPageForm } from '../../types/commonTypes';
import { getIsAdvancedSearch } from '../nav/navSlice';
import { setShowSignInModal } from '../sso/authSlice';

function SearchResults() {
  const {
    data,
    originalArgs,
    isLoading,
    isFetching: refetchingData,
  } = useCurrentQuery();

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('API Response:', data);
  }, [data]);

  const dispatch = useAppDispatch();
  const searchQuery = useAppSelector(getSearchQuery);
  const isAdvancedSearch = useAppSelector(getIsAdvancedSearch);
  const [filterOpen, setFilterOpen] = useState(false);
  const [newSearchInput, setNewSearchInput] = useState<string>(searchQuery?.resourceName?.resourceName || '');;
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<IResultsPageForm>();

  const onSubmit = ({ searchInput }: IResultsPageForm) => {
    if (!searchQuery) return;
    const { alternateId, referentType, ...rest } = searchQuery;
    const updatedQuery = {
      ...rest,
      resourceName: {
        ...rest?.resourceName,
        resourceName: searchInput,
      },
    };
    console.log('Updated Query:', updatedQuery); // Log the updated query
    dispatch(setSearchQuery(updatedQuery));
  };

  const {
    totalMatches, pageNumber, pageSize, currentSize, ...rest
  } = useMemo(
    () => (data || {} as any),
    [data],
  );

  const navigate = useNavigate();
  const sso = useSingleSignOn();
  // const [, logout] = useLoginLogout();
  useEffect(() => {
    if (sso?.isError) {
      navigate('/redirect');
    }
    if (!sso) {
      dispatch(setShowSignInModal(true));
    }
  }, [sso, navigate, dispatch]);

  useEffect(() => {
    // Log the search query
    console.log('Current Search Query:', searchQuery);
  }, [searchQuery]);

  useEffect(() => {
    if (originalArgs) {
      console.log('API Request:', {
        endpoint: concatUrl(config.apiUrl, config.search),
        body: {
          resolutionId: { ...originalArgs, token: '*****' },
        },
      });
    }
  }, [originalArgs]);

  const handleIconClick = () => {
    const values = getValues();
    onSubmit(values);
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newInput = e.target.value;
    setNewSearchInput(newInput);
  };


  return (
    <>
      <div className="lg:hidden">
        {filterOpen ? <RefineSearch setFilterOpen={setFilterOpen} /> : null}
      </div>
      <div className={filterOpen ? 'hidden lg:block' : ''}>
        <div className="w-full bg-theme-blue py-4 px-20 text-white text-2xl">
          Search Results
        </div>
        <div className="grid grid-cols-3 gap-4 px-4 lg:px-20 mt-14 max-w-7xl">
          <div className="col-span-3">
            <ViewJson
              response={data}
              request={
                originalArgs
                  ? {
                    endpoint: concatUrl(config.apiUrl, config.search),
                    body: {
                      resolutionId: { ...originalArgs, token: '*****' },
                    },
                  }
                  : undefined
              }
            >
              <NavLink
                to={isAdvancedSearch ? '/search/advanced' : '/'}
                className="text-theme-blue"
              >
                <FontAwesomeIcon icon={faChevronLeft} />
                <span className="ml-2">
                  Back to
                  {isAdvancedSearch && ' Advanced'}
                  {' '}
                  Search
                </span>
              </NavLink>
            </ViewJson>
          </div>

          <div className="hidden lg:block">
            <RefineSearch />
          </div>
          <div className="col-span-3 lg:col-span-2">
            <form className="mb-3 relative z-0" onSubmit={handleSubmit(onSubmit)}>
              <label>
                <input
                  type="text"
                  id="searchInput"
                  
                  {...register('searchInput', { required: 'This field is required.' })}
                  className="w-full p-3 border border-theme-gray-200 rounded"
                  value={newSearchInput}
                  onChange={handleInputChange}
                  placeholder="Search by title"
                />
              </label>
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                className="absolute right-4 top-1/2 -translate-y-1/2 cursor-pointer"
                onClick={handleIconClick}
              />
            </form>
            {errors?.searchInput && <p className="text-sm pb-1 text-red-400">{errors.searchInput.message}</p>}
            <div className="text-sm">
              <div className="mb-2 flex justify-between">
                <ClassicModeLink />
                {isAdvancedSearch ? <SearchBtn isAdvanced /> : <SearchBtn isAdvanced={false} />}
              </div>
              <button
                type="button"
                onClick={() => setFilterOpen(true)}
                className={classNames(
                  'flex items-center justify-center w-full lg:hidden',
                  'text-white bg-black py-2 rounded mb-2',
                )}
              >
                <FontAwesomeIcon icon={faFilter} />
                <span className="ml-2">Filter Results</span>
              </button>
              <div className="flex justify-between mb-4">
                <p className="">
                  Displaying
                  <select
                    className="bg-transparent mx-1 border rounded-sm px-1"
                    defaultValue={currentSize}
                    onChange={(e) => {
                      dispatch(
                        setSearchQuery({
                          ...searchQuery,
                          pageSize: Number(e.target.value),
                        }),
                      );
                    }}
                  >
                    <option>10</option>
                    <option>15</option>
                    <option>25</option>
                  </select>
                  of
                  <span className="mx-1">{totalMatches || 0}</span>
                  results
                </p>
                <SortAndOrder />
              </div>
            </div>
            {isLoading || refetchingData ? (
              <div className="h-8 w-full flex justify-center py-8 items-center">
                <Spinner />
              </div>
            ) : null}
            {!data && !searchQuery ? (
              <div className="my-4">
                <Error>
                  <p>Whoops, we&apos;ve ran into an issue.</p>
                  <div className="flex">
                    <Link to="/" className="text-theme-blue hover:underline">
                      Navigate Home
                    </Link>
                    <span className="pl-1"> or search by title above.</span>
                  </div>
                </Error>
              </div>
            ) : null}

            {data ? (
              <>
                {totalMatches > 0 && totalMatches < config.maxResults
                  ? rest.data.map((result: any) => (
                    <RecordCard key={getRandomString()} record={result} />
                  ))
                  : null}
                {totalMatches === 0 && (
                  <div className="my-4">
                    <Error>
                      <p className="px-2">
                        Hmm, we couldn&apos;t find any matching records. Please
                        revise your search.
                      </p>
                    </Error>
                  </div>
                )}
                {totalMatches > config.maxResults && (
                  <div className="my-4">
                    <Error>
                      <p className="px-2">
                        {`More than ${config.maxResults} matching records were found,
                  please refine your search.`}
                      </p>
                    </Error>
                  </div>
                )}
                {totalMatches < config.maxResults && (
                  <Pagination
                    pageNumber={pageNumber}
                    pageSize={pageSize}
                    totalMatches={totalMatches}
                  />
                )}
              </>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}

export default SearchResults;
