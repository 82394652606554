import React, { SetStateAction } from 'react';
import { faCirclePlus, faCircleMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function ExpandFieldBtn({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: React.Dispatch<SetStateAction<boolean>>;
}) {
  return (
    <button type="button" className="text-theme-gray-300 text-lg">
      {open ? (
        <FontAwesomeIcon icon={faCircleMinus} onClick={() => setOpen(false)} />
      ) : (
        <FontAwesomeIcon icon={faCirclePlus} onClick={() => setOpen(true)} />
      )}
    </button>
  );
}
