import React, { useState } from 'react';
import ExpandFieldBtn from '../../components/ExpandFieldBtn';

interface ExpandableJsonFieldProps {
  title: string;
  canOpen: boolean;
  contentWhenClosed: string;
  messageOnOpen: string;
  defaultOpen: boolean;
  children: React.ReactNode;
}

function ExpandableJsonField({
  title,
  canOpen,
  contentWhenClosed,
  messageOnOpen,
  defaultOpen,
  children,
}: ExpandableJsonFieldProps) {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  // Remove handleToggle function since it's not needed anymore

  return (
    <div className="text-md font-semibold w-full flex flex-col">
      {canOpen && (
        <div className="flex w-full justify-between my-2">
          <h2>{title}</h2>
          <ExpandFieldBtn open={isOpen} setOpen={setIsOpen} />
        </div>
      )}
      {isOpen ? (
        <div className="tableWrap">
          {children}
          <div className="messageOnOpen">{messageOnOpen}</div>
        </div>
      ) : (
        <div>{contentWhenClosed}</div>
      )}
    </div>
  );
}

export default ExpandableJsonField;
