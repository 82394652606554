import type {
  ReferentType,
  SearchPayloadLimited,
  TitleClass,
  SortOptions,
} from '../types/searchTypes';

export const baseQuery: SearchPayloadLimited = {
  customAsciiSearch: true,
  status: 'valid',
  pageNumber: 1,
  pageSize: 10,
  sort: 'title',
  order: 'desc',
};

export const sortOptions: SortOptions[] = [
  { name: 'title', value: 'title' },
  { name: 'date', value: 'date' },
  { name: 'length', value: 'length' },
  { name: 'country of origin', value: 'coo' },
  { name: 'referent type', value: 'reftype' },
  { name: 'original language', value: 'lang' },
];

export const titleClasses: TitleClass[] = [
  'abbreviated',
  'AKA',
  'broadcast',
  'fan-based',
  'FKA',
  'internal',
  'regional',
  'release',
  'series date',
  'series numeric',
  'transliterated',
  'working',
  'other',
];

export const referentTypes: ReferentType[] = [
  'root title records',
  'compilations',
  'seasons',
  'manifestations',
  'series',
  'episodes',
  'edits & clips',
];

export const refTypeOptions = [
  { value: 'root title records', name: 'Root Title Records' },
  { value: 'compilations', name: 'Compilations' },
  { value: 'seasons', name: 'Seasons' },
  { value: 'manifestations', name: 'Manifestations' },
  { value: 'series', name: 'Series' },
  { value: 'episodes', name: 'Episodes' },
];

export const structuralTypes = ['Abstraction', 'Performance', 'Digital'];

export const languageOptions = [{ value: 'om', label: 'om (Afan) Oromo' },
  { value: 'ab', label: 'ab Abkhazian' },
  { value: 'aa', label: 'aa Afar' },
  { value: 'af', label: 'af Afrikaans' },
  { value: 'sq', label: 'sq Albanian' },
  { value: 'am', label: 'am Amharic' },
  { value: 'ar', label: 'ar Arabic' },
  { value: 'hy', label: 'hy Armenian' },
  { value: 'as', label: 'as Assamese' },
  { value: 'ay', label: 'ay Aymara' },
  { value: 'az', label: 'az Azerbaijani' },
  { value: 'ba', label: 'ba Bashkir' },
  { value: 'bs', label: 'bs Bosnian' },
  { value: 'pt-BR', label: 'pt-BR Portuguese Brazilian' },
  { value: 'eu', label: 'eu Basque' },
  { value: 'bn', label: 'bn Bengali Bangla' },
  { value: 'dz', label: 'dz Bhutani' },
  { value: 'bh', label: 'bh Bihari' },
  { value: 'bi', label: 'bi Bislama' },
  { value: 'br', label: 'br Breton' },
  { value: 'bg', label: 'bg Bulgarian' },
  { value: 'my', label: 'my Burmese' },
  { value: 'be', label: 'be Byelorussian' },
  { value: 'km', label: 'km Cambodian' },
  { value: 'zh-yue', label: 'zh-yue Chinese Cantonese' },
  { value: 'ca', label: 'ca Catalan' },
  { value: 'zh', label: 'zh Chinese' },
  { value: 'co', label: 'co Corsican' },
  { value: 'hr', label: 'hr Croatian' },
  { value: 'cs', label: 'cs Czech' },
  { value: 'da', label: 'da Danish' },
  { value: 'nl', label: 'nl Dutch' },
  { value: 'en', label: 'en English' },
  { value: 'eo', label: 'eo Esperanto' },
  { value: 'et', label: 'et Estonian' },
  { value: 'fo', label: 'fo Faroese' },
  { value: 'fa', label: 'fa Farsi' },
  { value: 'fj', label: 'fj Fiji' },
  { value: 'fi', label: 'fi Finnish' },
  { value: 'fr', label: 'fr French' },
  { value: 'fy', label: 'fy Frisian' },
  { value: 'gl', label: 'gl Galician' },
  { value: 'ka', label: 'ka Georgian' },
  { value: 'de', label: 'de German' },
  { value: 'el', label: 'el Greek' },
  { value: 'kl', label: 'kl Greenlandic' },
  { value: 'gn', label: 'gn Guarani' },
  { value: 'gu', label: 'gu Gujarati' },
  { value: 'ha', label: 'ha Hausa' },
  { value: 'he', label: 'he Hebrew (formerly iw)' },
  { value: 'hi', label: 'hi Hindi' },
  { value: 'ht', label: 'ht Haitain' },
  { value: 'hu', label: 'hu Hungarian' },
  { value: 'is', label: 'is Icelandic' },
  { value: 'id', label: 'id Indonesian (formerly in)' },
  { value: 'ia', label: 'ia Interlingua' },
  { value: 'ie', label: 'ie Interlingue' },
  { value: 'iu', label: 'iu Inuktitut' },
  { value: 'ik', label: 'ik Inupiak' },
  { value: 'ga', label: 'ga Irish' },
  { value: 'it', label: 'it Italian' },
  { value: 'ja', label: 'ja Japanese' },
  { value: 'jv', label: 'jv Javanese' },
  { value: 'kn', label: 'kn Kannada' },
  { value: 'ks', label: 'ks Kashmiri' },
  { value: 'kk', label: 'kk Kazakh' },
  { value: 'rw', label: 'rw Kinyarwanda' },
  { value: 'ky', label: 'ky Kirghiz' },
  { value: 'rn', label: 'rn Kirundi' },
  { value: 'ko', label: 'ko Korean' },
  { value: 'ku', label: 'ku Kurdish' },
  { value: 'lo', label: 'lo Laothian' },
  { value: 'la', label: 'la Latin' },
  { value: 'lv', label: 'lv Latvian Lettish' },
  { value: 'ln', label: 'ln Lingala' },
  { value: 'lt', label: 'lt Lithuanian' },
  { value: 'mk', label: 'mk Macedonian' },
  { value: 'mg', label: 'mg Malagasy' },
  { value: 'ms', label: 'ms Malay' },
  { value: 'ml', label: 'ml Malayalam' },
  { value: 'mt', label: 'mt Maltese' },
  { value: 'zh-cmn', label: 'zh-cmn Chinese Mandarin' },
  { value: 'mi', label: 'mi Maori' },
  { value: 'mr', label: 'mr Marathi' },
  { value: 'mn', label: 'mn Mongolian' },
  { value: 'na', label: 'na Nauru' },
  { value: 'ne', label: 'ne Nepali' },
  { value: 'no', label: 'no Norwegian' },
  { value: 'oc', label: 'oc Occitan' },
  { value: 'or', label: 'or Oriya' },
  { value: 'ps', label: 'ps Pashto Pushto' },
  { value: 'pl', label: 'pl Polish' },
  { value: 'pt', label: 'pt Portuguese' },
  { value: 'pa', label: 'pa Punjabi' },
  { value: 'qu', label: 'qu Quechua' },
  { value: 'rm', label: 'rm Rhaeto-Romance' },
  { value: 'ro', label: 'ro Romanian' },
  { value: 'ru', label: 'ru Russian' },
  { value: 'sm', label: 'sm Samoan' },
  { value: 'sg', label: 'sg Sangho' },
  { value: 'sa', label: 'sa Sanskrit' },
  { value: 'gd', label: 'gd Scots Gaelic' },
  { value: 'sr', label: 'sr Serbian' },
  { value: 'sh', label: 'sh Serbo-Croatian' },
  { value: 'st', label: 'st Sesotho' },
  { value: 'tn', label: 'tn Setswana' },
  { value: 'sn', label: 'sn Shona' },
  { value: 'sd', label: 'sd Sindhi' },
  { value: 'si', label: 'si Sinhalese' },
  { value: 'ss', label: 'ss Siswati' },
  { value: 'sk', label: 'sk Slovak' },
  { value: 'sl', label: 'sl Slovenian' },
  { value: 'so', label: 'so Somali' },
  { value: 'es', label: 'es Spanish' },
  { value: 'su', label: 'su Sundanese' },
  { value: 'sw', label: 'sw Swahili' },
  { value: 'sv', label: 'sv Swedish' },
  { value: 'tl', label: 'tl Tagalog' },
  { value: 'tg', label: 'tg Tajik' },
  { value: 'ta', label: 'ta Tamil' },
  { value: 'tt', label: 'tt Tatar' },
  { value: 'te', label: 'te Telugu' },
  { value: 'th', label: 'th Thai' },
  { value: 'bo', label: 'bo Tibetan' },
  { value: 'ti', label: 'ti Tigrinya' },
  { value: 'to', label: 'to Tonga' },
  { value: 'ts', label: 'ts Tsonga' },
  { value: 'tr', label: 'tr Turkish' },
  { value: 'tk', label: 'tk Turkmen' },
  { value: 'tw', label: 'tw Twi' },
  { value: 'ug', label: 'ug Uighur' },
  { value: 'uk', label: 'uk Ukrainian' },
  { value: 'ur', label: 'ur Urdu' },
  { value: 'uz', label: 'uz Uzbek' },
  { value: 'vi', label: 'vi Vietnamese' },
  { value: 'vo', label: 'vo Volapuk' },
  { value: 'cy', label: 'cy Welsh' },
  { value: 'wo', label: 'wo Wolof' },
  { value: 'xh', label: 'xh Xhosa' },
  { value: 'yi', label: 'yi Yiddish (formerly ji)' },
  { value: 'yo', label: 'yo Yoruba' },
  { value: 'za', label: 'za Zhuang' },
  { value: 'zu', label: 'zu Zulu' },
  { value: 'other', label: 'Other' },
  { value: 'zxx', label: 'zxx No Linguistic Content' }];

export const countryOptions = [{ value: 'AF', label: 'AF Afghanistan' },
  { value: 'AX', label: 'AX Aland Islands' },
  { value: 'AL', label: 'AL Albania' },
  { value: 'DZ', label: 'DZ Algeria' },
  { value: 'AS', label: 'AS American Samoa' },
  { value: 'AD', label: 'AD Andorra' },
  { value: 'AO', label: 'AO Angola' },
  { value: 'AI', label: 'AI Anguilla' },
  { value: 'AQ', label: 'AQ Antarctica' },
  { value: 'AG', label: 'AG Antigua And Barbuda' },
  { value: 'AR', label: 'AR Argentina' },
  { value: 'AM', label: 'AM Armenia' },
  { value: 'AW', label: 'AW Aruba' },
  { value: 'AU', label: 'AU Australia' },
  { value: 'AT', label: 'AT Austria' },
  { value: 'AZ', label: 'AZ Azerbaijan' },
  { value: 'BS', label: 'BS Bahamas' },
  { value: 'BH', label: 'BH Bahrain' },
  { value: 'BD', label: 'BD Bangladesh' },
  { value: 'BB', label: 'BB Barbados' },
  { value: 'BY', label: 'BY Belarus' },
  { value: 'BE', label: 'BE Belgium' },
  { value: 'BZ', label: 'BZ Belize' },
  { value: 'BJ', label: 'BJ Benin' },
  { value: 'BM', label: 'BM Bermuda' },
  { value: 'BT', label: 'BT Bhutan' },
  { value: 'BO', label: 'BO Bolivia, Plurinational State Of' },
  { value: 'BA', label: 'BA Bosnia And Herzegovina' },
  { value: 'BW', label: 'BW Botswana' },
  { value: 'BV', label: 'BV Bouvet Island' },
  { value: 'BR', label: 'BR Brazil' },
  { value: 'IO', label: 'IO British Indian Ocean Territory' },
  { value: 'BN', label: 'BN Brunei Darussalam' },
  { value: 'BG', label: 'BG Bulgaria' },
  { value: 'BF', label: 'BF Burkina Faso' },
  { value: 'BI', label: 'BI Burundi' },
  { value: 'KH', label: 'KH Cambodia' },
  { value: 'CM', label: 'CM Cameroon' },
  { value: 'CA', label: 'CA Canada' },
  { value: 'CV', label: 'CV Cape Verde' },
  { value: 'KY', label: 'KY Cayman Islands' },
  { value: 'CF', label: 'CF Central African Republic' },
  { value: 'TD', label: 'TD Chad' },
  { value: 'CL', label: 'CL Chile' },
  { value: 'CN', label: 'CN China' },
  { value: 'CX', label: 'CX Christmas Island' },
  { value: 'CC', label: 'CC Cocos (keeling) Islands' },
  { value: 'CO', label: 'CO Colombia' },
  { value: 'KM', label: 'KM Comoros' },
  { value: 'CG', label: 'CG Congo' },
  { value: 'CD', label: 'CD Congo, The Democratic Republic Of The' },
  { value: 'CK', label: 'CK Cook Islands' },
  { value: 'CR', label: 'CR Costa Rica' },
  { value: 'CI', label: "CI Cote D'ivoire" }, { value: 'HR', label: 'HR Croatia' },
  { value: 'CU', label: 'CU Cuba' },
  { value: 'CW', label: 'CW Curaçao' },
  { value: 'CY', label: 'CY Cyprus' },
  { value: 'CZ', label: 'CZ Czechia' },
  { value: 'CSHH', label: 'CSHH Czechoslovakia' },
  { value: 'VDVN', label: 'VDVN Democratic Republic of Vietnam' },
  { value: 'YDYE', label: 'YDYE Democratic Yemen (South Yemen)' },
  { value: 'DK', label: 'DK Denmark' },
  { value: 'DJ', label: 'DJ Djibouti' },
  { value: 'DM', label: 'DM Dominica' },
  { value: 'DO', label: 'DO Dominican Republic' },
  { value: 'EC', label: 'EC Ecuador' },
  { value: 'EG', label: 'EG Egypt' },
  { value: 'SV', label: 'SV El Salvador' },
  { value: 'GQ', label: 'GQ Equatorial Guinea' },
  { value: 'ER', label: 'ER Eritrea' },
  { value: 'EE', label: 'EE Estonia' },
  { value: 'SZ', label: 'SZ Eswatini' },
  { value: 'ET', label: 'ET Ethiopia' },
  { value: 'FK', label: 'FK Falkland Islands (malvinas)' },
  { value: 'FO', label: 'FO Faroe Islands' },
  { value: 'FJ', label: 'FJ Fiji' },
  { value: 'FI', label: 'FI Finland' },
  { value: 'FR', label: 'FR France' },
  { value: 'GF', label: 'GF French Guiana' },
  { value: 'PF', label: 'PF French Polynesia' },
  { value: 'TF', label: 'TF French Southern Territories' },
  { value: 'GA', label: 'GA Gabon' },
  { value: 'GM', label: 'GM Gambia' },
  { value: 'GE', label: 'GE Georgia' },
  { value: 'DDDE', label: 'DDDE German Democratic Republic' },
  { value: 'DE', label: 'DE Germany' },
  { value: 'GH', label: 'GH Ghana' },
  { value: 'GI', label: 'GI Gibraltar' },
  { value: 'GR', label: 'GR Greece' },
  { value: 'GL', label: 'GL Greenland' },
  { value: 'GD', label: 'GD Grenada' },
  { value: 'GP', label: 'GP Guadeloupe' },
  { value: 'GU', label: 'GU Guam' },
  { value: 'GT', label: 'GT Guatemala' },
  { value: 'GG', label: 'GG Guernsey' },
  { value: 'GN', label: 'GN Guinea' },
  { value: 'GW', label: 'GW Guinea-bissau' },
  { value: 'GY', label: 'GY Guyana' },
  { value: 'HT', label: 'HT Haiti' },
  { value: 'HM', label: 'HM Heard Island And Mcdonald Islands' },
  { value: 'VA', label: 'VA Holy See (Vatican City State)' },
  { value: 'HN', label: 'HN Honduras' },
  { value: 'HK', label: 'HK Hong Kong' },
  { value: 'HU', label: 'HU Hungary' },
  { value: 'IS', label: 'IS Iceland' },
  { value: 'IN', label: 'IN India' },
  { value: 'ID', label: 'ID Indonesia' },
  { value: 'IR', label: 'IR Iran, Islamic Republic Of' },
  { value: 'IQ', label: 'IQ Iraq' },
  { value: 'IE', label: 'IE Ireland' },
  { value: 'IM', label: 'IM Isle Of Man' },
  { value: 'IL', label: 'IL Israel' },
  { value: 'IT', label: 'IT Italy' },
  { value: 'JM', label: 'JM Jamaica' },
  { value: 'JP', label: 'JP Japan' },
  { value: 'JE', label: 'JE Jersey' },
  { value: 'JO', label: 'JO Jordan' },
  { value: 'KZ', label: 'KZ Kazakhstan' },
  { value: 'KE', label: 'KE Kenya' },
  { value: 'KI', label: 'KI Kiribati' },
  { value: 'KP', label: "KP Korea, Democratic People's Republic Of" }, {
    value: 'KR',
    label: 'KR Korea, Republic Of',
  },
  { value: 'XK', label: 'XK Kosovo' },
  { value: 'KW', label: 'KW Kuwait' },
  { value: 'KG', label: 'KG Kyrgyzstan' },
  { value: 'LA', label: "LA Lao People's Democratic Republic" }, { value: 'LV', label: 'LV Latvia' },
  { value: 'LB', label: 'LB Lebanon' },
  { value: 'LS', label: 'LS Lesotho' },
  { value: 'LR', label: 'LR Liberia' },
  { value: 'LY', label: 'LY Libyan Arab Jamahiriya' },
  { value: 'LI', label: 'LI Liechtenstein' },
  { value: 'LT', label: 'LT Lithuania' },
  { value: 'LU', label: 'LU Luxembourg' },
  { value: 'MO', label: 'MO Macao' },
  { value: 'MG', label: 'MG Madagascar' },
  { value: 'MW', label: 'MW Malawi' },
  { value: 'MY', label: 'MY Malaysia' },
  { value: 'MV', label: 'MV Maldives' },
  { value: 'ML', label: 'ML Mali' },
  { value: 'MT', label: 'MT Malta' },
  { value: 'MH', label: 'MH Marshall Islands' },
  { value: 'MQ', label: 'MQ Martinique' },
  { value: 'MR', label: 'MR Mauritania' },
  { value: 'MU', label: 'MU Mauritius' },
  { value: 'YT', label: 'YT Mayotte' },
  { value: 'MX', label: 'MX Mexico' },
  { value: 'FM', label: 'FM Micronesia, Federated States Of' },
  { value: 'MD', label: 'MD Moldova, Republic Of' },
  { value: 'MC', label: 'MC Monaco' },
  { value: 'MN', label: 'MN Mongolia' },
  { value: 'ME', label: 'ME Montenegro' },
  { value: 'MS', label: 'MS Montserrat' },
  { value: 'MA', label: 'MA Morocco' },
  { value: 'MZ', label: 'MZ Mozambique' },
  { value: 'MM', label: 'MM Myanmar' },
  { value: 'NA', label: 'NA Namibia' },
  { value: 'NR', label: 'NR Nauru' },
  { value: 'NP', label: 'NP Nepal' },
  { value: 'NL', label: 'NL Netherlands' },
  { value: 'ANHH', label: 'ANHH Netherlands Antilles' },
  { value: 'NC', label: 'NC New Caledonia' },
  { value: 'NZ', label: 'NZ New Zealand' },
  { value: 'NI', label: 'NI Nicaragua' },
  { value: 'NE', label: 'NE Niger' },
  { value: 'NG', label: 'NG Nigeria' },
  { value: 'NU', label: 'NU Niue' },
  { value: 'NF', label: 'NF Norfolk Island' },
  { value: 'MK', label: 'MK North Macedonia' },
  { value: 'MP', label: 'MP Northern Mariana Islands' },
  { value: 'NO', label: 'NO Norway' },
  { value: 'OM', label: 'OM Oman' },
  { value: 'PK', label: 'PK Pakistan' },
  { value: 'PW', label: 'PW Palau' },
  { value: 'PS', label: 'PS Palestine, State of' },
  { value: 'PA', label: 'PA Panama' },
  { value: 'PG', label: 'PG Papua New Guinea' },
  { value: 'PY', label: 'PY Paraguay' },
  { value: 'PE', label: 'PE Peru' },
  { value: 'PH', label: 'PH Philippines' },
  { value: 'PN', label: 'PN Pitcairn' },
  { value: 'PL', label: 'PL Poland' },
  { value: 'PT', label: 'PT Portugal' },
  { value: 'PR', label: 'PR Puerto Rico' },
  { value: 'QA', label: 'QA Qatar' },
  { value: 'RE', label: 'RE Reunion' },
  { value: 'RO', label: 'RO Romania' },
  { value: 'RU', label: 'RU Russian Federation' },
  { value: 'RW', label: 'RW Rwanda' },
  { value: 'BQ', label: 'BQ Saba (Bonaire, Sint Eustatius and Saba)' },
  { value: 'BL', label: 'BL Saint Barthelemy' },
  { value: 'SH', label: 'SH Saint Helena, Ascension / Tristan Da Cunha' },
  { value: 'KN', label: 'KN Saint Kitts and Nevis' },
  { value: 'LC', label: 'LC Saint Lucia' },
  { value: 'MF', label: 'MF Saint Martin' },
  { value: 'PM', label: 'PM Saint Pierre And Miquelon' },
  { value: 'VC', label: 'VC Saint Vincent And The Grenadines' },
  { value: 'WS', label: 'WS Samoa' },
  { value: 'SM', label: 'SM San Marino' },
  { value: 'ST', label: 'ST Sao Tome And Principe' },
  { value: 'SA', label: 'SA Saudi Arabia' },
  { value: 'SN', label: 'SN Senegal' },
  { value: 'RS', label: 'RS Serbia' },
  { value: 'CSXX', label: 'CSXX Serbia and Montenegro' },
  { value: 'SC', label: 'SC Seychelles' },
  { value: 'SL', label: 'SL Sierra Leone' },
  { value: 'SG', label: 'SG Singapore' },
  { value: 'SX', label: 'SX Sint Maarten' },
  { value: 'SK', label: 'SK Slovakia' },
  { value: 'SI', label: 'SI Slovenia' },
  { value: 'SB', label: 'SB Solomon Islands' },
  { value: 'SO', label: 'SO Somalia' },
  { value: 'ZA', label: 'ZA South Africa' },
  { value: 'GS', label: 'GS South Georgia And South Sandwich Islands' },
  { value: 'SS', label: 'SS South Sudan' },
  { value: 'ES', label: 'ES Spain' },
  { value: 'LK', label: 'LK Sri Lanka' },
  { value: 'SD', label: 'SD Sudan' },
  { value: 'SR', label: 'SR Suriname' },
  { value: 'SJ', label: 'SJ Svalbard And Jan Mayen' },
  { value: 'SE', label: 'SE Sweden' },
  { value: 'CH', label: 'CH Switzerland' },
  { value: 'SY', label: 'SY Syrian Arab Republic' },
  { value: 'TW', label: 'TW Taiwan (Province of China' },
  { value: 'TJ', label: 'TJ Tajikistan' },
  { value: 'TZ', label: 'TZ Tanzania, United Republic Of' },
  { value: 'TH', label: 'TH Thailand' },
  { value: 'TL', label: 'TL Timor-leste' },
  { value: 'TG', label: 'TG Togo' },
  { value: 'TK', label: 'TK Tokelau' },
  { value: 'TO', label: 'TO Tonga' },
  { value: 'TT', label: 'TT Trinidad And Tobago' },
  { value: 'TN', label: 'TN Tunisia' },
  { value: 'TR', label: 'TR Turkey' },
  { value: 'TM', label: 'TM Turkmenistan' },
  { value: 'TC', label: 'TC Turks And Caicos Islands' },
  { value: 'TV', label: 'TV Tuvalu' },
  { value: 'UG', label: 'UG Uganda' },
  { value: 'UA', label: 'UA Ukraine' },
  { value: 'AE', label: 'AE United Arab Emirates' },
  { value: 'GB', label: 'GB United Kingdom' },
  { value: 'US', label: 'US United States' },
  { value: 'UM', label: 'UM United States Minor Outlying Islands' },
  { value: 'UY', label: 'UY Uruguay' },
  { value: 'SUHH', label: 'SUHH USSR' },
  { value: 'UZ', label: 'UZ Uzbekistan' },
  { value: 'VU', label: 'VU Vanuatu' },
  { value: 'VE', label: 'VE Venezuela, Bolivarian Republic Of' },
  { value: 'VN', label: 'VN Vietnam' },
  { value: 'VG', label: 'VG Virgin Islands, British' },
  { value: 'VI', label: 'VI Virgin Islands, U.S.' },
  { value: 'WF', label: 'WF Wallis And Futuna' },
  { value: 'EH', label: 'EH Western Sahara' },
  { value: 'YE', label: 'YE Yemen' },
  { value: 'YUCS', label: 'YUCS Yugoslavia' },
  { value: 'ZM', label: 'ZM Zambia' },
  { value: 'ZW', label: 'ZW Zimbabwe' }];
