import { getSearchQuery } from '../features/search/searchSlice';
import { getIdToken } from '../features/sso/authSlice';
import { useSearchQuery } from '../store';
import { useAppSelector } from './reduxHooks';

export default function useCurrentQuery(skip: boolean = false) {
  const searchQuery = useAppSelector(getSearchQuery);
  const token = useAppSelector(getIdToken);
  const query = useSearchQuery(
    { body: searchQuery || {}, token: token || '' },
    {
      skip: !searchQuery || !token || skip,
    },
  );

  return query;
}
