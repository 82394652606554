import React, { ReactNode } from 'react';
import ExpandableFieldOpen from '../features/resolve/ExpandableFieldOpen';

interface ViewXMLProps {
  children?: ReactNode;
  response?: any; // Define response prop if needed
}

const ViewXML: React.FC<ViewXMLProps> = function ViewXMLComponent({ children, response }) {
  return (
    <div className="my-2 bg-theme-blue bg-opacity-5 border border-theme-blue border-opacity-20 shadow-md rounded flex flex-col p-4 mb-4">
      {children}
      <div className="font-semibold my-2">
        <ExpandableFieldOpen
          title="Full Resolution Metadata"
          data={{}} // Empty data
          canOpen
          messageOnOpen={(
            <pre className="font-light bg-theme-gray-100 p-2 shadow-md mt-2">
              <div className="text-xs overflow-x-auto break-all">
                XML Full Resolution is not available.
              </div>
            </pre>
          )}
          defaultOpen
        />
      </div>
      <div className="font-semibold my-2">
        <ExpandableFieldOpen
          title="Self-Defined Metadata"
          data={{}} // Empty data
          canOpen
          messageOnOpen={(
            <pre className="font-light bg-theme-gray-100 p-2 shadow-md mt-2">
              <div className="text-xs overflow-x-auto break-all">
                {response ? JSON.stringify(response, null, 2) : ''}
                XML Self-Defined Metadata is not available.
              </div>
            </pre>
          )}
          defaultOpen
        />
      </div>
      <div className="font-semibold my-2">
        <ExpandableFieldOpen
          title="Provenance Metadata"
          data={{}} // Empty data
          canOpen
          messageOnOpen={(
            <pre className="font-light bg-theme-gray-100 p-2 shadow-md mt-2">
              <div className="text-xs overflow-x-auto break-all">
                XML Provenance Metadata is not available.
              </div>
            </pre>
          )}
          defaultOpen
        />
      </div>
    </div>
  );
};

export default ViewXML;
