import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../types/commonTypes';
import { IResolveState } from '../../types/resolutionTypes';

const initialState: IResolveState = {
  resolutionId: '',
};

const resolutionSlice = createSlice({
  name: 'resolution',
  initialState,
  reducers: {
    setResolutionId(state, action: { payload: { resolutionId: string } }) {
      state.resolutionId = action.payload.resolutionId;
    },
  },
});

export default resolutionSlice.reducer;

export const { setResolutionId } = resolutionSlice.actions;

export const getResolutionId = ({ resolve }: RootState) => resolve.resolutionId;
