import { PropsWithChildren } from 'react';
import { classNames } from '../utils/commonUtils';

export default function Error({ children }: PropsWithChildren) {
  return (
    <div className="w-full h-full flex justify-center items-center">
      <div
        className={classNames(
          'flex flex-col w-full h-full max-h-16 max-w-xl border justify-center items-center',
          'border-red-400 rounded text-center bg-red-50 py-8',
        )}
      >
        {children}
      </div>
    </div>
  );
}
