import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Error from './Error';

export default function Redirect() {
  const [countdown, setCountdown] = useState(5);

  const countdownInterval = useRef<any>();
  useEffect(() => {
    countdownInterval.current = setInterval(() => {
      setCountdown(() => countdown - 1);
    }, 1000);
  }, [countdown]);

  const navigate = useNavigate();

  useEffect(() => {
    if (countdown === 0) {
      clearInterval(countdownInterval.current);
      navigate('/');
    }
  }, [countdown, navigate]);

  return (
    <Error>
      <p>
        Whoops, an error has occurred. You will be redirected home in
        {' '}
        {countdown}
        {' '}
        seconds.
      </p>
    </Error>
  );
}
