import React, { ReactNode, useState } from 'react';
import { classNames } from '../utils/commonUtils';
import brackets from '../assets/brackets.svg';

interface PrettyPrintProps {
  data?: any;
  title: string;
}

const PrettyPrint: React.FC<PrettyPrintProps> = function PrettyPrintComponent({ data, title }) {
  return data ? (
    <>
      <span className="font-semibold my-2">{`${title}:`}</span>
      <div className="w-full h-[1px] bg-theme-gray-200 opacity-20" />
      <pre className="font-light bg-theme-gray-100 p-2 shadow-md mt-2">
        <div className="text-xs overflow-x-auto break-all">
          {JSON.stringify(data, null, 2)}
        </div>
      </pre>
    </>
  ) : null;
};

interface ViewJsonProps {
  request?: object;
  response: object;
  children?: ReactNode; // Make children optional
  selfDefined?: string;
  provenance?: string;
}

const ViewJson: React.FC<ViewJsonProps> = function ViewJsonComponent({
  request,
  response,
  children,
  selfDefined,
  provenance,
}) {
  const [open, setOpen] = useState(false);

  return (
    <div className="w-full">
      <div className="w-full flex justify-between mb-4 items-center">
        {children}
        <button
          type="button"
          className="w-max text-theme-blue flex items-center"
          onClick={() => setOpen(!open)}
        >
          <img src={brackets} alt="code brackets" />
          <span className="ml-1">View JSON</span>
        </button>
      </div>
      {open && (
        <div
          className={classNames(
            'bg-theme-blue bg-opacity-5 border border-theme-blue',
            'border-opacity-20 shadow-md rounded flex flex-col p-4',
            'mb-4',
          )}
        >
          {(response || request) && (
            <>
              <PrettyPrint data={request} title="Request" />
              <PrettyPrint data={response} title="Response" />
            </>
          )}

          {!response && !request && <p>No data available</p>}

          {selfDefined && (
            <div className="mt-4">
              <span className="font-semibold">Self-Defined:</span>
              {' '}
              {selfDefined}
            </div>
          )}

          {provenance && (
            <div className="mt-4">
              <span className="font-semibold">Provenance:</span>
              {' '}
              {provenance}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ViewJson;
