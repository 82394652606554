import { useMemo, SetStateAction, useCallback } from 'react';
import {
  IPipeListFields,
  IRowData,
  IStackListItem,
} from '../types/commonTypes';
import {
  camelToTitleCase,
  getGenericStackList,
  getIDType,
  resolveQs,
} from '../utils/commonUtils';

const pipeListMapper = ([field, fieldValue]: [string, unknown]) => {
  const isEidrId = getIDType(String(fieldValue));

  const pipeListItem: IPipeListFields = {
    // TODO: Check for EIDR IDS and use link attribute
    name: camelToTitleCase(field),
  } as IPipeListFields;

  if (isEidrId) {
    pipeListItem.link = {
      text: fieldValue as string,
      to: resolveQs(fieldValue as string),
      type: 'link',
    };
  } else {
    pipeListItem.value = String(fieldValue);
  }

  return pipeListItem;
};

const untitledPipeList = (val: string[]) =>
  val.map((item: string) => {
    const isEidrId = getIDType(item);
    if (isEidrId) {
      return {
        link: {
          to: resolveQs(item),
          text: item,
          type: 'link',
        },
      };
    }
    return {
      value: item,
    };
  }) as IPipeListFields[];

const titledPipeList = (val: any) => {
  // Object with value and potentially subfields: compose a titled pipe list

  const { value, ...rest } = val;

  const list = Object.entries(rest).map(pipeListMapper);

  return [value, list];
};

const getRows = (
  data: any,
  openStates: { [key: string]: boolean },
  setOpenStates: (attr: string, open: boolean) => void,
  subRow = false,
) => {
  const rows: IRowData[] = [];
  if (data) {
    // map the metadata data
    data = Object.entries(data).forEach(([key, val]: [string, any]) => {
      if (
        ['pageNumber', 'pageSize', 'currentSize', 'totalMatches'].includes(key)
      ) {
        return null;
      }

      const isObjectOfObjects = !Array.isArray(val) && typeof val === 'object';
      const isArrayOfObjects = Array.isArray(val) && typeof val[0] === 'object';
      const isArrayOfStrings = Array.isArray(val) && typeof val[0] === 'string';
      const valIsString = typeof val === 'string';
      const isObjectWithValue = val.value;

      const rowData: IRowData = {
        fieldName: camelToTitleCase(key).toUpperCase(),
        subRow,
      };

      if (valIsString) {
        // Simple field value pair: show simple value

        const isEidrId = getIDType(val);

        if (isEidrId) {
          rowData.link = {
            to: resolveQs(val),
            type: 'link',
            text: val,
          };
        } else {
          rowData.value = val;
        }

        rows.push(rowData);
      } else if (isArrayOfStrings) {
        // Array of strings: compose an untitled pipe list
        rowData.pipeList = untitledPipeList(val);
        rows.push(rowData);
      } else if (isArrayOfObjects && val[0].value) {
        rows.push(rowData);

        const open = openStates[key];

        rowData.stackList = {
          link: '',
          list: val.map(getGenericStackList),
          open,
          setOpen: () => setOpenStates(key, !openStates[key]),
        };
      } else if (isArrayOfObjects && !val[0].value) {
        const open = openStates[key];

        rowData.stackList = {
          link: '',
          open,
          setOpen: () => setOpenStates(key, !openStates[key]),
          list: val.map(({ displayName, ...rest }) => {
            const stackList: IStackListItem = { value: displayName };

            if (rest) {
              stackList.pipeList = Object.entries(rest).map(pipeListMapper);
            }

            return stackList;
          }),
        };
        titledPipeList(val);
        rows.push(rowData);
      } else if (isObjectWithValue) {
        const [value, list] = titledPipeList(val);
        rowData.value = value;
        rowData.pipeList = list;
        rows.push(rowData);
      } else if (isObjectOfObjects && !val.value) {
        // create sub-table (recurse)
        rowData.subTable = true;
        const subRows = getRows(val, openStates, setOpenStates, true);
        rows.push(rowData, ...subRows);
      } else {
        // eslint-disable-next-line
        console.warn('Cannot parse this object: ', JSON.stringify(val));
      }
      return null;
    });
  }

  return rows;
};

export default function useGenericRows(
  data: any,
  openStates: any,
  setOpenStates: React.Dispatch<SetStateAction<any>>,
) {
  const setOpenStatesHelper = useCallback(
    (attr: string, open: boolean) => {
      setOpenStates({
        ...openStates,
        [attr]: open,
      });
    },
    [openStates, setOpenStates],
  );

  const tableRowData = useMemo(
    () => getRows(Object.values(data)[0], openStates, setOpenStatesHelper),
    [data, openStates, setOpenStatesHelper],
  );

  return tableRowData;
}
