import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import {
  camelToTitleCase,
  classNames,
  resolveQs,
} from '../../utils/commonUtils';
import CopyButton from '../../components/CopyButton';

function RecordCard({ record }: { record: any }) {
  const datalist = [
    { title: 'class', value: record.resourceName.titleClass || '-' },
    {
      title: 'country',
      value: record.countryOfOrigin[0] || '-',
      length: record.countryOfOrigin.length,
    },
    {
      title: 'language',
      value: record.originalLanguage[0].value || '-',
      length: record.originalLanguage.length,
    },
    { title: 'release date', value: String(record.releaseDate).split('-').join('/') || '-' },
  ];

  const { type: eomdType, parent: eomdParent } = useMemo(() => {
    if (record.extraObjectMetadata) {
      const [key, value]: [string, any] = Object.entries(
        record.extraObjectMetadata,
      )[0];
      const type = camelToTitleCase(key).split(' ').slice(0, 1);
      const { parent } = value;

      return { type, parent };
    }

    return { type: null, parent: null };
  }, [record]);

  return (
    <article
      className={classNames(
        'flex flex-col items-start p-4 w-full',
        'bg-theme-blue bg-opacity-5 border',
        'border-theme-blue border-opacity-20 rounded',
        'mb-5',
      )}
    >
      <div className="font-semibold text-black text-xl">
        {record.resourceName.value}
      </div>
      <div className="w-full h-[1px] bg-theme-gray-200 mt-2" />
      <div className="flex flex-wrap justify-between w-full items-center">
        <div className="mt-2">
          <div className="text-xs w-max col-span-2 flex items-center text-theme-blue">
            <Link className="mr-2" to={resolveQs(record.resolutionId)}>
              {record.resolutionId}
            </Link>
            <CopyButton content={record.resolutionId} />
          </div>
          <div className="text-sm font-semibold flex mt-1">
            <span className="mr-6">{record.referentType}</span>
            {eomdType && eomdParent ? (
              <p>
                <span className="capitalize">{eomdType}</span>
                <span> of </span>
                <span>(</span>
                <Link className="text-theme-blue" to={resolveQs(eomdParent)}>
                  view parent
                </Link>
                <span>)</span>
              </p>
            ) : null}
          </div>
        </div>
        <dl className="flex justify-between mt-2">
          {datalist.map((item, i) => (
            <div
              key={item.title}
              className={classNames(
                datalist[i + 1] ? 'mr-4 md:mr-8' : '',
                'block text-center',
              )}
            >
              <dt className="text-xs uppercase tracking-tighter">
                {item.title}
              </dt>
              <div className="flex justify-center">
                <dd className="text-sm font-semibold mr-1">
                  {item.value}
                </dd>
                {item.length > 1 ? (
                  <span className="text-sm text-theme-blue font-medium">
                    <Link to={resolveQs(record.resolutionId)}>
                      {`+${item.length - 1}`}
                    </Link>
                  </span>
                ) : null}
              </div>
            </div>
          ))}
        </dl>
      </div>
    </article>
  );
}

export default RecordCard;
