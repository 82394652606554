import { FaGlobe } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { classNames } from '../utils/commonUtils';
import useSingleSignOn from '../hooks/useSingleSignOn';
import { useAppDispatch } from '../hooks/reduxHooks';
import { setIsAdvancedSearch } from '../features/nav/navSlice';

export default function LinkBanner() {
  const sso = useSingleSignOn();
  const dispatch = useAppDispatch();
  const links = [
    {
      name: 'Register',
      url: '/register',
      type: 'link',
    },
    {
      name: 'Subscribe',
      type: 'anchor',
      url: 'https://share.hsforms.com/1nvRMxIK5TgyScT05Gcn6xgcda7r',
    },
    { name: 'Help', type: 'link', url: '/help' },
  ];

  return (
    <div className="w-full lg:px-20 py-2">
      <ul className="flex flex-col lg:flex-row lg:justify-between px-6 lg:px-0">
        <div className="flex">
          <li className="hover:underline text-theme-blue text-base font-roboto font-medium mr-5">
            <Link aria-label="link to Register" to="/register">
              Register
            </Link>
          </li>
          <li className="hover:underline text-theme-blue text-base font-roboto font-medium mr-5">
            <Link aria-label="link to Search" onClick={() => dispatch(setIsAdvancedSearch(false))} to="/search/">
              Search
            </Link>
          </li>
          {sso && (
            <li className="hover:underline text-theme-blue text-base font-roboto font-medium mr-5">
              <Link aria-label="link to Advanced Search" onClick={() => dispatch(setIsAdvancedSearch(true))} to="/search/advanced/">
                Advanced Search
              </Link>
            </li>
          )}
        </div>
        <li className="flex lg:hidden">
          {/* Empty space for alignment on small screens */}
          &nbsp;
        </li>
        <li className="flex lg:inline-flex">
          {/* Render Subscribe link */}
          <Link to="/language">
            <FaGlobe style={{
              color: 'steelblue',
              stroke: 'steelblue',
              fill: 'steelblue',
              marginRight: '15px',
              marginTop: '5px',
            }}
            />
          </Link>
          {links
            .filter((link) => link.name === 'Subscribe')
            .map(({ name, type, url }) => (
              <span key={`banner-link-${name}`} style={{ marginRight: '10px' }}>
                <a
                  className={classNames(
                    'hover:underline py-2 px-4 lg:px-0',
                    'text-theme-blue text-base font-roboto font-medium',
                  )}
                  target={type === 'anchor' ? '_blank' : undefined}
                  rel={type === 'anchor' ? 'noreferrer' : undefined}
                  href={url}
                >
                  {name}
                </a>
              </span>
            ))}
          {/* Render Help link */}
          {links
            .filter((link) => link.name === 'Help')
            .map(({ name, type, url }) => (
              <span key={`banner-link-${name}`} style={{ marginRight: '10px' }}>
                <a
                  className={classNames(
                    'hover:underline py-2 px-4 lg:px-0',
                    'text-theme-blue text-base font-roboto font-medium',
                  )}
                  target={type === 'anchor' ? '_blank' : undefined}
                  rel={type === 'anchor' ? 'noreferrer' : undefined}
                  href={url}
                >
                  {name}
                </a>
              </span>
            ))}
        </li>
      </ul>
    </div>
  );
}
