export default function EidrLoginModal({ onClose }: { onClose: () => void }) {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-auto bg-black bg-opacity-50">
      <div className="relative w-full max-w-md p-6 mx-auto my-10 bg-white rounded shadow-lg">
        <div className="flex-grow text-center">
          <h2 className="text-2xl font-semibold inline-block ">Sign In With EIDR</h2>
          <button
            type="button"
            className="absolute top-0 right-2 px-2 py-1 text-gray-700 hover:text-red-700 focus:outline-none"
            onClick={onClose}
            aria-label="Close"
          >
            &times;
          </button>
        </div>
        <div className="flex flex-col items-center justify-center mt-10 mb-10">
          <svg className="w-16 h-16 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
          <p className="mt-5 text-lg text-red-600">EIDR Authentication is currently not available. Please try again later.</p>
        </div>
        <button
          type="button"
          onClick={onClose}
          className="flex mt-2 items-center justify-center gap-3 mx-auto w-full pr-4 py-3 border-0 rounded shadow text-white cursor-pointer text-lg m-1 overflow-hidden h-14"
          style={{
            width: '30%', backgroundColor: '#2b3c51', height: '50px', marginLeft: '34%',
          }}
        >
          <span style={{ margin: '2px 0 0 13px' }}>Okay</span>
        </button>
      </div>
    </div>
  );
}
