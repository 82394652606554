import { Link } from 'react-router-dom';
import { IRowData } from '../types/commonTypes';
import PipeList from './PipeList';
import StackList from './StackList';
import DataLink from './DataLink';
import { classNames } from '../utils/commonUtils';

export default function TableRow({
  fieldName,
  value,
  pipeList,
  stackList,
  subTable,
  subRow,
  link,
}: IRowData) {
  const location = window.location.pathname;

  return (
    <tr
      className={classNames(
        subTable ? 'mt-1' : '',
        subRow || subTable ? 'bg-theme-gray-300 bg-opacity-5' : '',
        'w-full text-start grid grid-cols-3 px-4',
      )}
    >
      <td className={classNames(subRow ? 'ml-4' : '', 'col-span-1')}>
        <div className="flex flex-wrap w-max lg:max-w-[250px]">
          <p className="w-max mr-1">{fieldName}</p>
          {/** render show less/ show more button if stack list provided  */}
          {stackList ? (
            <div className="flex">
              {stackList.list.length > 1 ? (
                <>
                  {location.includes('view/content')
                    ? <p className="text-theme-blue">{`+${stackList.list.length - 1}`}</p> : (
                      <Link className="text-theme-blue" to={stackList.link}>
                        {`+${stackList.list.length - 1}`}
                      </Link>
                    )}

                  <button
                    className="ml-1"
                    type="button"
                    onClick={() => stackList.setOpen(!stackList.open)}
                  >
                    <span>(</span>
                    <span className="text-theme-blue">
                      {stackList.open ? 'SHOW LESS' : 'SHOW MORE'}
                    </span>
                    <span>)</span>
                  </button>
                </>
              ) : null}
            </div>
          ) : null}
        </div>
      </td>

      <td className="col-span-3 lg:col-span-2 pl-4 lg:pl-0">
        {/** Render simple value */}
        {value && !link ? <p>{value}</p> : null}

        {/** Render simple value */}
        {!value && link ? <DataLink link={link} /> : null}

        {/** Render pipe list if present without stacklist */}
        {pipeList && !stackList ? <PipeList fields={pipeList} /> : null}

        {/** Render open stack list */}
        {!pipeList && stackList && stackList.open ? (
          <StackList items={stackList.list} />
        ) : null}

        {/** Render closed stack list */}
        {!pipeList && stackList && !stackList.open ? (
          <StackList items={[stackList.list[0]]} />
        ) : null}
      </td>
    </tr>
  );
}
