export default function RegisterPage() {
  return (
    <>
      <div className="w-full bg-theme-blue py-4 px-20 text-white text-2xl">
        Register
      </div>
      <div className="max-w-2xl px-20 py-10">
        <div className="text-sm bg-gradient-to-r from-theme-white-100 p-10 to-transparent">
          <p>
            You must log in to register (or modify) works in the EIDR registry.
            If you already have an account, please log in. If you do not have an
            account, then:
          </p>
          <ul className="list-disc m-5 grid grid-flow-row gap-2 registerPageLinks">
            <li>
              If your company is an EIDR Member, contact your EIDR
              representative or request access via the
              {' '}
              <a href="mailto:support@eidr.org?subject=EIDR Account Assistance">EIDR Help Desk</a>
              .
            </li>
            <li>
              If you would like information about becoming an EIDR Member,
              please visit the
              {' '}
              <a href="https://www.eidr.org/membership/" target="_blank" rel="noreferrer">EIDR Web site.</a>
            </li>
            <li>
              If your registration needs do not warrant full EIDR membership,
              then please visit one of the EIDR retail registration agencies
              where you can register works singly or in smaller volumes.
            </li>
            <li className="flex flex-col pl-2">
              <li>
                {' '}
                <a href="https://titleregistrar.com/" target="_blank" rel="noreferrer">The Title Registrar (TTR)</a>
              </li>
              {' '}
              <li>
                <a href="https://bb.vision/flickshow-en/">BB Media</a>
              </li>
            </li>
            <li>
              If you would like to report an error, omission, or duplicate entry
              in the EIDR Registry, then please contact
              {' '}
              <a href="mailto:support@eidr.org?subject=EIDR Data Issue">EIDR Operations</a>
              . Be sure
              to include the affected EIDR ID and both a brief description of the issue and
              instructions on how it should be corrected.
            </li>
            <li>
              For more information, please see
              <a href="https://www.eidr.org/documents/How_to_Obtain_an_EIDR_ID.pdf"> How to Obtain an EIDR ID.</a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
