import { useMemo, useState } from 'react';
import { IContentRecord } from '../../types/resolutionTypes';
import CopyButton from '../../components/CopyButton';
import ExpandFieldBtn from '../../components/ExpandFieldBtn';
import ExpandableField from './ExpandableField';

export default function BaseObjectMetadata({
  record,
}: {
  record: IContentRecord;
}) {
  const [baseMdOpen, setBaseMdOpen] = useState(true);

  const {
    resourceName,
    resolutionId,
    referentType,
    countryOfOrigin,
    originalLanguages,
    releaseDate,
    ...rest
  } = useMemo(() => record, [record]);

  return (
    <>
      <div className="font-semibold text-xl flex justify-between w-full">
        <h2 className="w-max">{resourceName?.value}</h2>
        <ExpandFieldBtn open={baseMdOpen} setOpen={setBaseMdOpen} />
      </div>
      <div className="w-full h-[1px] bg-theme-gray-200 bg-opacity-50 my-3" />

      <div className="flex flex-wrap w-full justify-between mb-2">
        <div className="text-sm w-max text-theme-blue mt-2">
          <div className="flex items-center">
            <span className="mr-2">{resolutionId}</span>
            <CopyButton content={resolutionId} />
          </div>

          <p className="font-semibold text-black">{referentType}</p>
        </div>
        <dl className="grid grid-cols-3 text-center gap-8 mt-2">
          <div>
            <dt className="font-regular text-xs">CLASS</dt>
            <dd className="font-semibold text-sm">
              {resourceName?.titleClass ? resourceName.titleClass : '-'}
            </dd>
          </div>
          <div>
            <dt className="font-regular text-xs">COUNTRY</dt>
            <dd className="font-semibold text-sm">
              {countryOfOrigin}
            </dd>
          </div>
          <div>
            <dt className="font-regular text-xs">DATE</dt>
            <dd className="font-semibold text-sm">{releaseDate}</dd>
          </div>
        </dl>
      </div>

      {baseMdOpen && (
        <ExpandableField defaultOpen canClose={false} data={{ data: rest }} />
      )}
    </>
  );
}
