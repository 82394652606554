import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../types/commonTypes';
import type { NavState } from '../../types/navTypes';

const initialState: NavState = {
  isAdvancedSearch: false,
};

const navSlice = createSlice({
  name: 'nav',
  initialState,
  reducers: {
    setIsAdvancedSearch(state, action: { payload: boolean }) {
      state.isAdvancedSearch = action.payload;
    },
  },
});

export default navSlice.reducer;
export const { setIsAdvancedSearch } = navSlice.actions;
export const getIsAdvancedSearch = (state: RootState) => state.nav.isAdvancedSearch;
