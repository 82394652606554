import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { classNames } from '../../utils/commonUtils';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { getIsAdvancedSearch, setIsAdvancedSearch } from '../nav/navSlice';

export default function SearchBtn({ isAdvanced }: { isAdvanced: boolean }) {
  const dispatch = useAppDispatch();
  const isAdvancedSearch = useAppSelector(getIsAdvancedSearch);
  const navigate = useNavigate();
  const advancedMsg = 'Switch to the Advanced Search screen for more search options.';
  const basicMsg = 'Switch to the Basic Search screen to resolve EIDR IDs and Alternate IDs and to search by Title';
  const handleSearchChange = () => {
    dispatch(setIsAdvancedSearch(!isAdvancedSearch));
    if (isAdvancedSearch) {
      navigate('/search/');
    } else {
      navigate('/search/advanced');
    }
  };
  return (
    <div className="text-theme-blue flex items-center">
      <button type="button" onClick={handleSearchChange} className="bg-none border-none p-0 text-theme-blue hover:underline cursor-pointer mr-2">
        <p>
          {isAdvanced ? 'Search' : 'Advanced Search'}
        </p>
      </button>
      <div className="relative group">
        <FontAwesomeIcon icon={faCircleInfo} />
        <div
          className={classNames(
            'hidden group-hover:block absolute max-w-[13rem]',
            'rounded-md w-max bg-theme-blue text-white',
            'p-1 text-xs right-0 z-20',
          )}
        >
          <p>
            {isAdvanced ? basicMsg : advancedMsg}
          </p>
        </div>
      </div>
    </div>
  );
}
