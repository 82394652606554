import React from 'react';
import '../App.css';

function ClassicModeLink() {
  return (
    <div className="text-xs">
      <a
        className="text-theme-blue hover:underline"
        href={`https://legacy-${window.location.host}`}
      >
        (Switch to Legacy Web UI)
      </a>
    </div>
  );
}

export default ClassicModeLink;
