export default function Help() {
  return (
    <>
      <div className="w-full bg-theme-blue py-4 px-20 text-white text-2xl">
        Help
      </div>
      <div className="max-w-2xl px-20 py-10">
        <div className="text-sm bg-gradient-to-r from-theme-white-100 p-10 to-transparent">
          <strong style={{ fontSize: '18px' }}>Web UI Users</strong>
          <p>
            There are three different kinds of EIDR Web-based user interface (Web UI) users.
            Each will find different features/capabilities in the EIDR Web UI:
          </p>
          <li>
            <strong>General Public: </strong>
            These are un-authenticated users (they have
            not logged into the EIDR Web UI). They can freely resolve
            any EIDR Content ID or Video Service ID and will receive
            back the associated descriptive metadata record. They are
            free to use EIDR IDs for any legal purpose without charge
            or further restriction.
          </li>
          <ul className="list-disc m-5 grid grid-flow-row gap-2 registerPageLinks">
            <li>
              The EIDR records returned will not contain any third-
              party identifiers (Alt IDs)
            </li>
            <li>
              Metadata-based search services are not available
            </li>
            <li>
              EIDR record creation/modification services are not available
            </li>
          </ul>
          <p>
            <li>
              <strong>EIDR Community Users: </strong>
              These users have signed into the EIDR
              Web UI using their Google or LinkedIn credentials.
              They have all the access privileges of the General Public
              (unauthenticated) users, plus:
            </li>
            <ul className="list-disc m-5 grid grid-flow-row gap-2 registerPageLinks">
              <li>They can view the full EIDR registry record, including all Alt IDs</li>
              <li>
                They can use descriptive metadata elements to search the EIDR registry for
                matching EIDR Content ID or Video Service records
              </li>
            </ul>
          </p>
          <p>
            <li>
              <strong>EIDR Members: </strong>
              These users have signed into the EIDR Web UI using their EIDR-issued user credentials.
              They can access all the features and functions of the EIDR Web UI, including EIDR
              record registration and modification
              If you are logged in, your persona will be identified in the upper,
              right-hand corner of the screen, along with the option to log out.
            </li>
            <p>
              <strong
                style={{
                  fontSize: '18px',
                  display: 'block',
                  marginTop: '10px',
                  marginBottom: '10px',
                }}
              >
                EIDR Basic Search
              </strong>
              <p>
                Enter an EIDR ID in the main search box in the middle of the screen and
                press the Enter key or click on the magnifying glass icon. If the EIDR ID is valid,
                you will receive back the record’s descriptive metadata.
                Click on the “Back to search” link to return to the Basic Search screen.
              </p>
              <li>
                <strong>Community Users and Members: </strong>
                In addition to resolving by EIDR ID, you can enter all or part of a
                work’s title in the search box and receive back matching records.
                If you search returns more than 500 records,
                then you will need to refine your search conditions to return fewer records.
                The Advanced Search option offers more search options.
              </li>
              <strong
                style={{
                  fontSize: '18px',
                  display: 'block',
                  marginTop: '10px',
                  marginBottom: '10px',
                }}
              >
                EIDR Advanced Search
              </strong>
              <li>
                <strong>Community Users and Members Only: </strong>
                Here you can specify a wide variety of metadata
                values and search conditions to perform a more complex search. If you search
                returns more than 500 records, then you will need to refine your search
                conditions to return fewer records.
              </li>
              <strong
                style={{
                  fontSize: '22px',
                  display: 'block',
                  marginTop: '10px',
                  marginBottom: '10px',
                }}
              >
                Web UI Menu
              </strong>
              <strong
                style={{
                  fontSize: '18px',
                  display: 'block',
                  marginTop: '10px',
                  marginBottom: '10px',
                }}
              >
                Register
              </strong>
              Only approved EIDR Members can register new EIDR IDs. Anyone else will receive a
              basic instruction page with links to:
              <p>
                <li>
                  The EIDR Help Desk for Web UI support questions
                </li>
                <li>
                  The EIDR Web page for information on becoming an EIDR Member
                </li>
                <li>
                  EIDR retail registration agencies where you can obtain
                  a new EIDR ID without being an EIDR member
                </li>
                <li>
                  More detailed instruction on how to obtain an EIDR ID
                  a new EIDR ID without being an EIDR member
                  for Members and non-Members
                </li>
                <strong
                  style={{
                    fontSize: '18px',
                    display: 'block',
                    marginTop: '10px',
                    marginBottom: '10px',
                  }}
                >
                  Subscribe
                </strong>
                This will take you to a form where you can subscribe to the EIDR newsletter,
                press releases, and other EIDR-related announcements.
                EIDR does not share its mailing list with third parties and will never
                sell your personal data.
              </p>
              <strong
                style={{
                  fontSize: '18px',
                  display: 'block',
                  marginTop: '10px',
                  marginBottom: '10px',
                }}
              >
                Help
              </strong>
              Selecting Help from the Web UI will take you to this page.
              You can also request assistance directly from the EIDR Help
              Desk by sending an email to support@eidr.org.
              <strong
                style={{
                  fontSize: '22px',
                  display: 'block',
                  marginTop: '10px',
                  marginBottom: '10px',
                }}
              >
                Web UI Versions
              </strong>
              EIDR Operates two different Web UI environments:
              <li>
                ui.eidr.org – This is the EIDR production environment. The EIDR IDs
                and their descriptive metadata can be relied upon, used in commercial
                automation, etc. It contains all the latest updates for all records.
              </li>
              <li>
                sandboxui.eidr.org – This is the EIDR user training and test
                environment. It is similar to production, but the EIDR IDs cannot be
                relied upon and the descriptive metadata may not be accurate.
                Do not use any of the materials from the EIDR sandbox for anything other than
                training.
                Sandbox is functionally identical to production,
                so you can rely on all of its behaviors
                and can use it as a reliable testbed for systems later released in production.
              </li>
            </p>
          </p>
        </div>
      </div>
    </>
  );
}
