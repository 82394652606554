import { useMemo, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  useInitLoginQuery,
  useTokenQuery,
  useUserQuery,
} from '../features/api/apiSlice';
import { getIdp, setIdToken } from '../features/sso/authSlice';
import { useAppDispatch, useAppSelector } from './reduxHooks';
import { getAuthorizationUrl, providerMap } from '../utils/authUtils';
import { Provider } from '../types/authTypes';

export default function useSingleSignOn() {
  const dispatch = useAppDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const url = window.location.origin;

  const { provider, idToken } = useAppSelector(getIdp);

  const { isSuccess: initSuccess } = useInitLoginQuery(undefined, {
    skip: !provider || !!idToken,
  });

  useEffect(() => {
    if (initSuccess && provider) {
      const socialProvider: Provider = providerMap.get(provider)!;
      const providerUrl = getAuthorizationUrl(
        socialProvider.authorizationEndpoint,
        socialProvider.clientId,
        socialProvider.redirectUri,
        socialProvider.responseType,
        socialProvider.scope,
      );

      sessionStorage.setItem('idp', JSON.stringify({ provider, idToken }));
      window.location.replace(providerUrl);
    }
  }, [initSuccess, provider, idToken]);

  const codeUrlParam = searchParams.get('code');
  const stateUrlParam = searchParams.get('state');
  const sessionState = sessionStorage.getItem('auth_login_state');
  const { provider: sessionProvider, idToken: sessionIdToken } = JSON.parse(
    sessionStorage.getItem('idp') || '{}',
  );

  const skipTokenQuery = useMemo(() => {
    // ?skip if there isn't sufficient parameters
    if (!codeUrlParam || !sessionProvider || !url) return true;

    // ?skip if the state doesn't match
    if (sessionState !== stateUrlParam) return true;

    // ?skip if there is already an idToken
    if (idToken) return true;

    return false;
  }, [
    codeUrlParam,
    sessionProvider,
    url,
    sessionState,
    stateUrlParam,
    idToken,
  ]);

  const { data: tokenQuery } = useTokenQuery(
    { code: codeUrlParam || '', idp: sessionProvider || '', redirect_uri: url },
    {
      skip: skipTokenQuery,
    },
  );

  const {
    data: userQuery,
    isSuccess: userQuerySuccess,
    error,
  } = useUserQuery(
    { token: tokenQuery?.id_token || idToken },
    { skip: !tokenQuery && !idToken },
  );

  useEffect(() => {
    switch (true) {
      // @ts-ignore
      // Reinitialize the login if the user is not authenticated
      case error?.status === 401:
        dispatch(setIdToken({ idToken: null }));
        sessionStorage.setItem(
          'idp',
          JSON.stringify({ provider: provider || sessionProvider }),
        );
        break;
      case !!sessionIdToken:
        dispatch(setIdToken({ idToken: sessionIdToken }));
        break;
      case !!(tokenQuery && userQuery):
        sessionStorage.setItem(
          'idp',
          JSON.stringify({
            provider: sessionProvider,
            idToken: tokenQuery.id_token,
          }),
        );
        dispatch(setIdToken({ idToken: tokenQuery.idToken }));
        setSearchParams('');
        break;
      default:
        break;
    }
  }, [
    tokenQuery,
    userQuery,
    setSearchParams,
    dispatch,
    error,
    provider,
    idToken,
    sessionIdToken,
    sessionProvider,
  ]);

  if (userQuerySuccess && userQuery) {
    return { ...userQuery.result.payload };
  }

  return undefined;
}
