import React from 'react';
import SocialLogins from './SocialLogins';
import { setShowSignInModal } from './authSlice';
import { useAppDispatch } from '../../hooks/reduxHooks';
import useLoginLogout from '../../hooks/useLoginLogout';

function SignInModal() {
  const dispatch = useAppDispatch();
  const [, logout] = useLoginLogout();
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-auto bg-black bg-opacity-50">
      <div className="relative w-full max-w-md p-6 mx-auto my-10 bg-white rounded shadow-lg">
        <div className="flex-grow text-center">
          <h2 className="text-2xl font-semibold inline-block ">Sign In</h2>
          <button
            className="absolute top-0 right-2 px-2 py-1 text-gray-700 hover:text-red-700 focus:outline-none"
            onClick={() => {
              dispatch(setShowSignInModal(false));
              logout();
            }}
            type="button"
          >
            &times;
          </button>
        </div>
        <div className="mt-6">
          <div className="text-center">
            <div>
              <SocialLogins />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignInModal;
