import React, { useState, ReactNode } from 'react';
import ExpandFieldBtn from '../../components/ExpandFieldBtn';
import useGenericRows from '../../hooks/useGenericRows';
import DataTable from '../../components/DataTable';

export default function ExpandableFieldOpen({
  title,
  data,
  canOpen = true,
  defaultOpen = false,
  setOpen,
  contentWhenClosed = null,
  messageOnOpen = 'Content is now available',
}: {
  title?: string;
  data: any;
  defaultOpen?: boolean;
  canOpen?: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  contentWhenClosed?: string | null;
  messageOnOpen?: ReactNode; // Update to accept ReactNode
}) {
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const [attrOpenStates, setAttrOpenStates] = useState<any>({});
  const tableRowData = useGenericRows(data, attrOpenStates, setAttrOpenStates);

  const handleToggle = () => {
    if (setOpen) {
      setOpen((prev) => !prev);
    } else {
      setIsOpen((prev) => !prev);
    }
  };

  return (
    <div className="text-md font-semibold w-full flex flex-col">
      {canOpen && (
        <div className="flex w-full justify-between my-2">
          <h2>{title}</h2>
          <ExpandFieldBtn open={isOpen} setOpen={handleToggle} />
        </div>
      )}
      {isOpen && tableRowData ? (
        <div className="tableWrap">
          <div className="w-full h-[1px]" />
          <DataTable tableRowData={tableRowData} />
          <div className="messageOnOpen">{messageOnOpen}</div>
        </div>
      ) : (
        !isOpen && contentWhenClosed && <div>{contentWhenClosed}</div>
      )}
    </div>
  );
}
