import { Dispatch, SetStateAction } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faClose } from '@fortawesome/free-solid-svg-icons';
import config from '../config';
import { EidrLogo } from '../assets/EidrHeaderAssets';
import UserProfile from '../features/sso/UserProfile';
import useSingleSignOn from '../hooks/useSingleSignOn';
import { classNames } from '../utils/commonUtils';
import { useAppSelector } from '../hooks/reduxHooks';
import { getShowSignInModal } from '../features/sso/authSlice';
import useLoginLogout from '../hooks/useLoginLogout';

function EidrHeader({
  setShowMobileMenu,
}: {
  setShowMobileMenu: Dispatch<SetStateAction<boolean>>;
}) {
  const sso = useSingleSignOn();

  const showSignInModal = useAppSelector(getShowSignInModal);
  const [toggleSignIn] = useLoginLogout();

  // TODO: Use loading state from useSingSignOn to show a spinner
  // TODO: in header when user data is loading

  return (
    <header
      className={classNames(
        `${config.bannerColor}`,
        'items-center justify-between text-white flex',
        'flex-col lg:flex-row md:px-20 md:py-6 px-2 py-4 relative',
      )}
    >
      <Link to="/">
        <div className="items-center mx-auto sm:block">
          <EidrLogo />
        </div>
        <button
          type="button"
          className={classNames(
            'lg:hidden absolute top-2 right-3 md:right-6 text-black text-lg',
          )}
          onClick={() => {
            setShowMobileMenu(true);
          }}
        >
          {showSignInModal ? (
            <FontAwesomeIcon icon={faClose} />
          ) : (
            <FontAwesomeIcon icon={faBars} />
          )}
        </button>
      </Link>
      <div className="md:flex md:w-auto text-black font-roboto items-center">
        <h1 className="lg:pr-5 lg:text-2xl mt-1 md:text-lg sm:text-base text-sm font-light">
          Entertainment Identifier Registry
        </h1>
        <div className="hidden lg:block">
          {sso ? (
            <UserProfile />
          ) : (
            <button
              className={classNames(
                'hover:bg-opacity-80 transition-colors',
                'text-white bg-theme-blue py-2 px-4 text-sm font-medium rounded',
              )}
              type="button"
              onClick={toggleSignIn}
            >
              Sign in
            </button>
          )}
        </div>
      </div>
    </header>
  );
}

export default EidrHeader;
