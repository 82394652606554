import { ApiEndpoints, EnvConfig } from './types/appConfig';
// eslint-disable-next-line
import { composeConfig } from './utils/configUtils';

// Points to dev api deployment
export const dev: EnvConfig = {
  bannerColor: 'bg-[#73a600]',
  apiUrl: 'https://api-dev.eidr.org',
  envName: 'sandbox 3',
  displayOverlay: true,
};

export const qa: EnvConfig = {
  bannerColor: 'bg-[#FFA500]',
  apiUrl: '',
  envName: 'qa',
  displayOverlay: true,
};

// Points to localhost, so this needs backend running on port 8081
export const local: EnvConfig = {
  bannerColor: 'bg-[#006600]',
  apiUrl: 'http://localhost:8081',
  envName: 'local',
  displayOverlay: true,

};

// Points to mock server in postman
// Ensure API limit hasn't been reached for the month (10K requests)
export const mock: EnvConfig = {
  bannerColor: 'bg-[#FFFFFF]',
  apiUrl: 'https://17e5b1c7-3e8c-4008-a56a-2edbdf06a4fe.mock.pstmn.io',
  envName: 'mock',
  displayOverlay: true,

};

// example prod config, the bannerColor is the same as the designs
// export const prod: EnvConfig = {
//   bannerColor: 'bg-[#EEEEEE]',
//   apiUrl: '',
//   envName: prod,
//   displayOverlay: false,
// }

export const defaultConfig = {
  // Add common config values here
  GOOGLE_CLIENT_ID:
    '867643482644-2795tdkcvgd73rsgsph1lr0vujhgrvpk.apps.googleusercontent.com',
  GOOGLE_AUTH_URL: 'https://accounts.google.com/o/oauth2/v2/auth',
  LINKEDIN_CLIENT_ID: '86xqwi34vb2tp2',
  LINKEDIN_AUTH_URL: 'https://www.linkedin.com/oauth/v2/authorization',
  EIDR_LINKEDIN_URL: 'https://www.linkedin.com/company/eidr/',
  EIDR_TWITTER_URL: 'https://twitter.com/eidrdotorg?lang=en',
  maxResults: 500,
};

export const endpoints: ApiEndpoints = {
  v1: ['resolve', 'initlogin', 'token', 'search', 'user', 'docs', 'isonline'],
  // e.g. v2: ['resolve', ...etc]
};

export default composeConfig();
